/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { toast } from 'react-toastify';
import getDistance from 'geolib/es/getDistance';
import { CiClock2, CiCreditCard2, CiCirclePlus } from 'react-icons/ci';
import { FaMapMarkerAlt } from 'react-icons/fa';

import useLocation from 'hooks/useLocation';
import { useUserTelephone } from 'hooks/useUserTelephone';
import { useInformationsClient } from 'hooks/useInformationsClient';
import { useDateLogin } from 'hooks/useDateLogin';
import apiAutoatendimento from 'services/apiAutoatendimento';

import SpecificApiLocalClient from 'services/api';

import Category from 'components/Category';

import { FiSearch } from 'react-icons/fi';
import { BsWhatsapp } from 'react-icons/bs';

import { ProductsProps, CategoriesProps, InputRefProps } from 'models';

import Modal from 'components/Modal';
import Input from 'components/Input';

import LogoDatamais from 'assets/logoDatamais.png';
import { asyncLocalStorage, getStorage, setStorage } from 'services/storage';
import BannerModal from 'components/BannerModal';
import { theme } from '../../App';

import {
  Container,
  Main,
  ContainerTabs,
  ButtonSearch,
  ButtonWhats,
  ContainerHeader,
  ContainerDeliveryModal,
  ButtonDelivery,
} from './styles';
/* eslint-disable */
import { browserName } from 'react-device-detect';

import { DeliveryFeeControll } from '../../enums/delivery-fee-controll';

const Home: React.FC = () => {
  const api = SpecificApiLocalClient();
  const { accepted, getTelephoneNumber, setAccepted } = useUserTelephone();
  const { companyData } = useInformationsClient();
  const { setCompanyData } = useInformationsClient();
  const { updateUserLoginData } = useDateLogin();
  const location = useLocation();

  const [value, setValue] = useState(0);
  const [showSearch, setShowSearch] = useState(false);
  const [localRadius, setLocalRadius] = useState(false);
  const [modalPasswordPartner, setModalPasswordPartner] = useState(false);
  const [modalBalancePartner, setModalBalancePartner] = useState(false);

  const [products, setProducts] = useState<ProductsProps[]>([]);
  const [backupProducts, setBackupProducts] = useState<ProductsProps[]>([]);
  const [categories, setCategories] = useState<CategoriesProps[]>([]);
  const [filteredCategories, setfilteredCategories] = useState<
    CategoriesProps[]
  >([]);

  const [showModalStart, setShowModalStart] = useState(false);
  const [showModalPaymentsDelivery, setShowModalPaymentsDelivery] =
    useState(false);
  const [showModalDeliveryHours, setShowModalDeliveryHours] = useState(false);
  const [adBanner, setAdBanner] = useState(false);

  const inputPhone = useRef<InputRefProps>(null);
  const inputGuestCheck = useRef<InputRefProps>(null);
  const inputCustomer = useRef<InputRefProps>(null);
  const [balance, setBalance] = useState();
  const [timeStatus, setTimeStatus] = useState({} as any);

  const [optionsPayment, setOptionsPayment] = useState([]);
  const [isOpenDelivery, setIsOpenDelivery] = useState(false);
  const [urlGoogleMapsDelivery, setUrlGoogleMapsDelivery] = useState('');
  const [deliveryTime, setDeliveryTime] = useState('-');
  const [deliveryTimeWithDrawal, setDeliveryTimeWithDrawal] = useState('-');
  const [deliveryTimeList, setDeliveryTimeList] = useState([]);
  const [deliveryMoreInfo, setDeliveryMoreInfo] = useState('');

  const handleChangeCategories = (
    event: React.ChangeEvent<Record<string, unknown>>,
    newValue: number,
  ) => {
    setValue(newValue);
  };

  const validateCellphoneNumber = (cellphone: string): boolean => {
    const regex = new RegExp(
      '^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$',
    );
    const number = cellphone
      .replace(/\s+/g, '')
      .replace('-', '')
      .replace('(', '')
      .replace(')', '');
    return regex.test(number);
  };
  useEffect(() => {
    // eslint-disable-next-line
    let diferenceBetweenLocations = 0;
    if (
      location.coordinates.lat !== null &&
      location.coordinates.lng !== null
    ) {
      diferenceBetweenLocations = getDistance(
        {
          latitude: location.coordinates.lat,
          longitude: location.coordinates.lng,
        },
        {
          latitude: companyData.lat,
          longitude: companyData.lon,
        },
      );
    }
    const json_qrcode = {
      check_qrcode: {
        id: companyData.companyCode,
        nr_mesa: companyData.table,
      },
    };

    if (
      getStorage('companyCode') &&
      getStorage('descClient') &&
      getStorage('command') &&
      getStorage('table') &&
      getStorage('lat') &&
      getStorage('dtSave') &&
      !companyData.companyCode
    ) {
      const dtSave = new Date(getStorage('dtSave').replaceAll('"', ''));

      dtSave.setHours(dtSave.getHours() + 1);

      const dtAtual = new Date();

      // if (dtAtual > dtSave) {
      // eslint-disable-next-line
      if (false) {
        localStorage.clear();
      } else {
        console.log('Ignore clear local storage');
        setCompanyData({
          companyCode: Number(getStorage('companyCode').replaceAll('"', '')),
          desClient: getStorage('descClient').replaceAll('"', ''),
          table: Number(getStorage('table').replaceAll('"', '')),
          command: Number(getStorage('command').replaceAll('"', '')),
          guestCheckPad: Number(
            getStorage('guestCheckPad').replaceAll('"', ''),
          ),
          tip: Boolean(getStorage('useTip').replaceAll('"', '')),
          mandatoryTipTax: Boolean(
            getStorage('useMandatoryTipTax').replaceAll('"', ''),
          ),
          cellPhone: '',
          lat: getStorage('lat').replaceAll('"', ''),
          lon: getStorage('lon').replaceAll('"', ''),
          radius: Number(getStorage('radius').replaceAll('"', '')),
          ngrok: getStorage('ngrok').replaceAll('"', ''),
          attendantCode: Number(
            getStorage('attendantCode').replaceAll('"', ''),
          ),
          printerCode: Number(getStorage('printerCode').replaceAll('"', '')),
          setCustomer: getStorage('setCustomer').replaceAll('"', ''),
          setPhone: getStorage('setPhone').replaceAll('"', ''),
          setLocation: getStorage('setLocation').replaceAll('"', ''),
          setBanner: getStorage('setBanner').replaceAll('"', ''),
          setGroups: getStorage('setGroups').replaceAll('"', ''),
          setPartnerPassword:
            getStorage('setPartnerPassword') !== null
              ? getStorage('setPartnerPassword').replaceAll('"', '')
              : 'N',
          setBlockConference: getStorage('setBlockConference').replaceAll(
            '"',
            '',
          ),
          setDelivery: getStorage('setDelivery').replaceAll('"', ''),
          setDeliveryTable: Number(
            getStorage('setDeliveryTable').replaceAll('"', ''),
          ),
          setNotification: getStorage('setNotification').replaceAll('"', ''),
          setMenu: getStorage('setMenu').replaceAll('"', ''),
          setMenuTable: Number(getStorage('setMenuTable').replaceAll('"', '')),
          setAutoConference: getStorage('setAutoConference').replaceAll(
            '"',
            '',
          ),
          setAutoConferenceTable: Number(
            getStorage('setAutoConferenceTable').replaceAll('"', ''),
          ),
          setPriceItemTaste: getStorage('setPriceItemTaste').replaceAll(
            '"',
            '',
          ),
          setGetLocalDelivery: getStorage('setGetLocalDelivery').replaceAll(
            '"',
            '',
          ),
          setDeliveryAddress: getStorage('setGetLocalDelivery').replaceAll(
            '"',
            '',
          ),
          setWhatsAppNumber: getStorage('setWhatsAppNumber').replaceAll(
            '"',
            '',
          ),
          setWhatsAppText: getStorage('setGetLocalDelivery').replaceAll(
            '"',
            '',
          ),
        });
        if (
          Number(getStorage('companyCode').replaceAll('"', '')) &&
          !companyData.companyCode
        ) {
          companyData.companyCode = Number(
            getStorage('companyCode').replaceAll('"', ''),
          );
        }
      }
    }
    const startOrder = () => {
      if (json_qrcode.check_qrcode.id !== 0) {
        apiAutoatendimento
          .post('check_qrcode', json_qrcode)
          .then(responseQrCode => {
            const qrCodeStatus = responseQrCode.data.response.data.is_valid;
            if (qrCodeStatus === false) {
              toast.error(
                'Você deve ler um QRCode ativo para acessar o cardápio',
                {
                  autoClose: 10000,
                },
              );
            } else if (qrCodeStatus === 'invalid') {
              toast.error(
                'Você deve ler um QRCode válido para acessar o cardápio',
                {
                  autoClose: 10000,
                },
              );
            } else {
              setLocalRadius(true);
              api
                .get(`/fprg_GetGruposJSON_AA/${companyData.companyCode}/0/0/`)
                .then(response => {
                  let filterResponse = [];
                  // eslint-disable-next-line prefer-destructuring
                  filterResponse = response.data.result[0];
                  filterResponse = filterResponse.filter(val => {
                    return val.DS_GRUPO !== 'Favoritos';
                  });
                  setCategories(filterResponse);
                  setfilteredCategories([filterResponse[0]]);
                })
                .catch(error => {
                  console.log(error);
                  toast.error('Erro no carregamento dos Produtos');
                });
              // Check Delivery or AA
              api
                .get(
                  `/${
                    companyData.table === companyData.setDeliveryTable &&
                    companyData.setDelivery === 'S'
                      ? 'fprg_GetProdutosJSON_Delivery'
                      : 'fprg_GetProdutosJSON_AA'
                  }/${companyData.companyCode}/`,
                )
                .then(response => {
                  if (companyData.setDelivery === 'S') {
                    api
                      .get(
                        `fprg_GetTaxadeEntregaJSON/${companyData.companyCode}/`,
                      )
                      .then(responseNgrokTaxa => {
                        localStorage.setItem(
                          'taxaControll',
                          responseNgrokTaxa.data.result[0].IN_ADICIONAL_ENTREGA,
                        );
                        localStorage.setItem(
                          'dataTaxas',
                          JSON.stringify(responseNgrokTaxa.data.result[0]),
                        );
                      })
                      .catch(error => {
                        localStorage.setItem('taxaControll', DeliveryFeeControll.STREET);
                        localStorage.setItem('dataTaxas', '{}');
                        console.log(
                          'Erro ao acessar as taxas do estabelecimento',
                        );
                        console.log(error);
                      });
                  }

                  setProducts(response.data.result[0]);
                  setBackupProducts(response.data.result[0]);
                })
                .catch(error => {
                  console.log(error);
                  if (error.response !== undefined) {
                    if (Number(error.response.status) === 500) {
                      api
                        .get(
                          `/fprg_GetProdutosJSON/${companyData.companyCode}/`,
                        )
                        .then(response => {
                          setProducts(response.data.result[0]);
                          setBackupProducts(response.data.result[0]);
                        })
                        .catch(() => {
                          toast.error('Erro no carregamento dos Produtos');
                        });
                    } else {
                      toast.error('Erro no carregamento dos Produtos');
                      console.log(error.message);
                    }
                  } else {
                    toast.error('Erro no carregamento dos Produtos');
                    console.log(error.message);
                  }
                });
            }
          })
          .catch(error => {
            throw new Error(`Erro ao validar QR Code: ${error}`);
          });
      }
    };

    if (companyData.companyCode) {
      if (companyData.setLocation === 'S') {
        console.log('Use location');
        if (typeof location.coordinates.lat === 'number') {
          // eslint-disable-next-line
          console.log(
            `Diference between locations: ${diferenceBetweenLocations}`,
          );
          if (diferenceBetweenLocations <= companyData.radius) {
            // eslint-disable-next-line
            startOrder();
          } else {
            toast.error(
              'Você deve estar perto do estabelecimento para acessar o cardápio',
            );
          }
        }
      } else {
        console.log('Ignore location');
        startOrder();
      }
    } else {
      toast.error('Você deve ler um QRCode para acessar o cardápio');
    }
  }, [companyData]);

  useEffect(() => {
    if (!accepted) {
      setTimeout(() => {
        setShowModalStart(true);
      }, 1500);
    }
  }, [accepted]);

  useEffect(() => {
    const lastCategory = localStorage.getItem('lastCategory');
    if (categories.length > 0) {
      setfilteredCategories([categories[Number(lastCategory)]]);
    }
    setValue(Number(lastCategory));
  }, [categories]);

  useEffect(() => {
    if (companyData.setBlockConference === 'S') {
      api
        .get(
          `/fprg_IncluirContaVerificarPessoaJSON/${companyData.command}/${companyData.table}/${companyData.companyCode}/1/0/`,
        )
        .then(result => {
          const prjResult = String(result.data.result[0].msg);
          if (prjResult.includes('Conta já conferida!')) {
            if (accepted) {
              setAccepted(false);
              toast.warning('Indisponível, solicite a liberação da mesa!', {
                autoClose: 8000,
              });
            }
          }
        });
    }
  }, [accepted]);

  const setCompanyParameters = (command: number, cellPhone: string) => {
    const idStorage = getStorage('id_user');
    const date = new Date();
    const minutes = `0${date.getMinutes()}`.slice(-2);
    const hours = `0${date.getHours()}`.slice(-2);
    const today = `${hours}:${minutes}`;
    setCompanyData({
      companyCode: Number(companyData.companyCode),
      desClient: companyData.desClient,
      command,
      guestCheckPad: Number(companyData.guestCheckPad),
      tip: companyData.tip,
      mandatoryTipTax: companyData.mandatoryTipTax,
      cellPhone,
      table: Number(companyData.table),
      lat: companyData.lat,
      lon: companyData.lon,
      radius: Number(companyData.radius),
      ngrok: companyData.ngrok,
      attendantCode: Number(companyData.attendantCode),
      printerCode: Number(companyData.printerCode),
      setCustomer: companyData.setCustomer,
      setPhone: companyData.setPhone,
      setLocation: companyData.setLocation,
      setBanner: companyData.setBanner,
      setGroups: companyData.setGroups,
      setPartnerPassword: companyData.setPartnerPassword,
      setBlockConference: companyData.setBlockConference,
      setDelivery: companyData.setDelivery,
      setDeliveryTable: companyData.setDeliveryTable,
      setNotification: companyData.setNotification,
      setMenu: companyData.setMenu,
      setMenuTable: companyData.setMenuTable,
      setAutoConference: companyData.setAutoConference,
      setAutoConferenceTable: companyData.setAutoConferenceTable,
      setPriceItemTaste: companyData.setPriceItemTaste,
      setGetLocalDelivery: companyData.setGetLocalDelivery,
      setDeliveryAddress: companyData.setDeliveryAddress,
      setWhatsAppNumber: companyData.setWhatsAppNumber,
      setWhatsAppText: companyData.setWhatsAppText,
    });

    const cellPhoneStorage = getStorage('cellPhone').replaceAll('"', '');
    const commandStorage = getStorage('command').replaceAll('"', '');
    if (cellPhone !== cellPhoneStorage || command !== Number(commandStorage)) {
      setStorage('cart', []);
    }

    if (idStorage !== cellPhone || idStorage !== String(command)) {
      setStorage('cellPhone', cellPhone);
      setStorage('command', command);
      if (cellPhone !== '') {
        setStorage('id_user', cellPhone);
      } else {
        setStorage('id_user', command);
      }
    }
    // TODO: fix this inheritance
    getTelephoneNumber(''); // legacy code, used to set accepted
    updateUserLoginData(date, today);
  };

  const showBanner = (option: string) => {
    if (option === 'S') {
      setTimeout(() => {
        setAdBanner(true);
      }, 2000);
    }
  };

  const startOrder = () => {
    const phoneValue = inputPhone.current?.getValue().toString();
    const customerValue =
      inputCustomer.current?.getValue().toString() !== undefined
        ? inputCustomer.current?.getValue().toString()
        : '';
    if (customerValue !== undefined) {
      setStorage('setUserCode', customerValue);
    }
    const guestCheckValue = inputGuestCheck.current?.getValue().toString();

    const phoneNumberValue = String(phoneValue)
      .replace(/\s+/g, '')
      .replace('-', '')
      .replace('(', '')
      .replace(')', '');

    if (companyData.setMenuTable !== 0) {
      if (companyData.table !== companyData.setDeliveryTable) {
        if (companyData.guestCheckPad === 1) {
          switch (companyData.setPhone) {
            case 'O':
              if (guestCheckValue.length >= 1) {
                if (phoneValue.length > 1) {
                  if (validateCellphoneNumber(phoneValue)) {
                    setCompanyParameters(
                      Number(guestCheckValue),
                      phoneNumberValue,
                    );
                  } else {
                    toast.error(
                      'Você deve informar um número de celular válido',
                    );
                  }
                } else {
                  toast.error('Você deve informar um número de celular válido');
                }
              } else {
                toast.error('Você deve informar o número da comanda');
              }
              break;
            case 'P':
              if (guestCheckValue.length >= 1) {
                if (phoneValue.length > 1) {
                  if (validateCellphoneNumber(phoneValue)) {
                    setCompanyParameters(
                      Number(guestCheckValue),
                      phoneNumberValue,
                    );
                  } else {
                    toast.error(
                      'Você deve informar um número de celular válido',
                    );
                  }
                } else {
                  setCompanyParameters(Number(guestCheckValue), '');
                }
              } else {
                toast.error('Você deve informar o número da comanda');
              }
              break;
            case 'C': {
              const phoneWithoutDDD = Number(
                phoneNumberValue.substring(
                  phoneNumberValue.length - 9,
                  phoneNumberValue.length,
                ),
              );
              if (phoneValue.length > 1) {
                if (validateCellphoneNumber(phoneValue)) {
                  setCompanyParameters(phoneWithoutDDD, phoneNumberValue);
                } else {
                  toast.error('Você deve informar um número de celular válido');
                }
              } else {
                toast.error('Você deve informar um número de celular válido');
              }
              break;
            }
            case 'N':
              if (guestCheckValue.length >= 1) {
                setCompanyParameters(Number(guestCheckValue), '');
              } else {
                toast.error('Você deve informar o número da comanda');
              }
              break;
            default:
              toast.error('Você deve ler um QRCode para acessar o cardápio');
              break;
          }
          showBanner(companyData.setBanner);
        } else {
          const customerNumberValue = String(customerValue)
            .replace(/\s+/g, '')
            .replace('-', '')
            .replace('(', '')
            .replace(')', '');
          const phoneWithoutDDD = Number(
            phoneNumberValue.substring(
              phoneNumberValue.length - 9,
              phoneNumberValue.length,
            ),
          );

          switch (companyData.setPhone) {
            case 'C': {
              if (phoneValue.length > 1) {
                if (validateCellphoneNumber(phoneValue)) {
                  setCompanyParameters(phoneWithoutDDD, phoneNumberValue);
                } else {
                  toast.error('Você deve informar um número de celular válido');
                }
              } else {
                toast.error('Você deve informar um número de celular válido');
              }
              break;
            }
            case 'N': {
              if (companyData.setCustomer === 'S') {
                if (customerValue.length >= 1) {
                  if (companyData.setPartnerPassword === 'S') {
                    const userCode = getStorage('setUserCode').replace(
                      /["]/g,
                      '',
                    );
                    let user = '';
                    api
                      .get(
                        `/fprg_GetPessoaJSON/${companyData.companyCode}/${userCode}`,
                      )
                      .then(response => {
                        user = response.data;
                        if (user['result']['0']['CD_PESSOA'] == userCode) {
                          setModalPasswordPartner(true);
                          setCompanyParameters(Number(customerNumberValue), '');
                        } else {
                          setShowModalStart(true);
                          toast.warn('Usuário inválido');
                        }
                      })
                      .catch(err => {
                        console.error('Ocorreu um erro' + err);
                      });
                  } else {
                    setCompanyParameters(Number(customerNumberValue), '');
                  }
                } else {
                  toast.error('Você deve informar um número de cliente');
                }
              } else {
                setCompanyParameters(companyData.table, '');
              }
              break;
            }
            case 'O': {
              if (phoneValue.length > 1) {
                if (validateCellphoneNumber(phoneValue)) {
                  if (companyData.setCustomer === 'S') {
                    if (customerValue.length >= 1) {
                      setCompanyParameters(
                        Number(customerNumberValue),
                        phoneNumberValue,
                      );
                    } else {
                      toast.error('Você deve informar um número de cliente');
                    }
                  } else {
                    setCompanyParameters(companyData.table, phoneNumberValue);
                  }
                } else {
                  toast.error('Você deve informar um número de celular válido');
                }
              } else {
                toast.error('Você deve informar um número de celular válido');
              }
              break;
            }
            case 'P': {
              if (companyData.setCustomer === 'S') {
                if (customerValue.length >= 1) {
                  if (phoneValue.length > 1) {
                    if (validateCellphoneNumber(phoneValue)) {
                      setCompanyParameters(
                        Number(customerNumberValue),
                        phoneNumberValue,
                      );
                    } else {
                      toast.error(
                        'Você deve informar um número de celular válido',
                      );
                    }
                  } else {
                    setCompanyParameters(Number(customerNumberValue), '');
                  }
                } else {
                  toast.error('Você deve informar um número de cliente');
                }
              } else if (phoneValue.length > 1) {
                if (validateCellphoneNumber(phoneValue)) {
                  setCompanyParameters(companyData.table, phoneNumberValue);
                } else {
                  toast.error('Você deve informar um número de celular válido');
                }
              } else {
                setCompanyParameters(companyData.table, '');
              }
              break;
            }
            default:
              toast.error('Você deve ler um QRCode para acessar o cardápio');
              break;
          }
          showBanner(companyData.setBanner);
        }
      } else {
        setShowModalStart(false);
        setCompanyParameters(0, '');
      }
    } else {
      setShowModalStart(false);
      if (companyData.setCustomer === 'S') {
        if (customerValue === '') {
          setCompanyParameters(Number(companyData.table), '');
        } else {
          setCompanyParameters(Number(customerValue), '');
        }
      } else {
        setCompanyParameters(0, '');
      }
      setShowModalStart(false);
    }
  };

  const proceedModal = useCallback(() => {
    if (companyData.setBlockConference === 'S') {
      api
        .get(
          `/fprg_IncluirContaVerificarPessoaJSON/${companyData.command}/${companyData.table}/${companyData.companyCode}/1/0/`,
        )
        .then(result => {
          const prjResult = String(result.data.result[0].msg);
          if (prjResult.includes('Conta já conferida!')) {
            toast.warning('Indisponível, solicite a liberação da mesa!', {
              autoClose: 8000,
            });
            setAccepted(false);
          } else {
            startOrder();
          }
        });
    } else {
      startOrder();
    }
  }, [
    companyData.setBlockConference,
    companyData.setCustomer,
    companyData.attendantCode,
    companyData.lat,
    companyData.lon,
  ]);

  const returnBalance = () => {
    const userCode = getStorage('setUserCode').replace(/["]/g, '');
    let user_balance = '';
    api
      .get(`/fprg_GetSaldoClienteJSON/${companyData.companyCode}/${userCode}`)
      .then(response => {
        user_balance = response.data;
        if (user_balance['result']['0']['0']) {
          let formaterBalance = user_balance['result']['0']['0'][
            'SALDO'
          ].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
          setBalance(formaterBalance);
          setModalBalancePartner(true);
          setModalPasswordPartner(false);
        } else {
          toast.warn('Algo de errado');
        }
      })
      .catch(err => {
        console.error('Ocorreu um erro' + err);
      });
  };

  const validPasswordPartner = () => {
    const passwordPartner = (
      document.getElementById('passwordPartner') as HTMLInputElement
    ).value;
    const userCode = getStorage('setUserCode').replace(/["]/g, '');
    let user_password = '';
    api
      .get(
        `/fprg_GetSenhaPessoaJSON/${companyData.companyCode}/${userCode}/${passwordPartner}`,
      )
      .then(response => {
        user_password = response.data;
        if (user_password['result']['0']['ID_DM001']) {
          returnBalance();
        } else {
          toast.warn('Senha inválida');
        }
      })
      .catch(err => {
        console.error('Ocorreu um erro' + err);
      });
  };

  useEffect(() => {
    if (
      companyData.setAutoConference === 'S' &&
      companyData.table !== companyData.setDeliveryTable &&
      companyData.table === companyData.setAutoConferenceTable &&
      companyData.setMenuTable !== companyData.table
    ) {
      console.log('conferencia');
      asyncLocalStorage.setItem('cart', '').then(() => {
        setStorage('lastCart', '');
        setStorage('lastCategory', 0);
        setAccepted(false);
      });
    }
  }, []);

  useEffect(() => {
    let type = 'A';

    if (
      companyData.setDelivery === 'S' &&
      companyData.table === companyData.setDeliveryTable
    ) {
      type = 'D';
    }

    const dataTime = {
      id_dm000: companyData.companyCode,
      type: type,
    };

    apiAutoatendimento
      .post('validation_time', dataTime)
      .then(response => {
        setTimeStatus(response.data);
      })
      .catch(error => {
        console.log(`Erro ao buscar horarios: ${error}`);
      });
  }, [companyData]);

  useEffect(() => {
    if (showModalPaymentsDelivery) {
      api
        .get(`/fprg_GetFormaPagamentoJSON/`)
        .then(response => {
          let list = response.data.result[0];
          list = list.filter(item => !item.DS_FORMA_PAGAMENTO.endsWith('-WEB'));
          setOptionsPayment(list);
        })
        .catch(e => {
          console.log(e);
        });
    }
  }, [showModalPaymentsDelivery]);

  useEffect(() => {
    const json_company = {
      Id_dm000: companyData.companyCode,
    };
    apiAutoatendimento
      .post('get_location_aa', json_company)
      .then(response => {
        const data = response.data.response.data;
        setDeliveryTimeWithDrawal(data.get_location.in_txt_withDrawal_delivery);
        setDeliveryTime(data.get_location.in_txt_time_delivery);
        setDeliveryMoreInfo(data.get_location.in_more_info_delivery);
        setUrlGoogleMapsDelivery(data.get_location.in_link_maps_delivery);
        const scheduleData = data.get_location.JS_HR_FUNCIONAMENTO;
        try {
          const jsonSchedule = JSON.parse(scheduleData);
          if (jsonSchedule.length > 0) {
            const resultArray = [];

            jsonSchedule.forEach(item => {
              item.days.forEach(day => {
                if (day.intervals.length > 0) {
                  const intervalsString = day.intervals
                    .map(interval => `${interval.start} - ${interval.end}`)
                    .join(' ');
                  resultArray.push(`${day.day}: ${intervalsString}`);
                }
              });
            });

            console.log(resultArray);
            setDeliveryTimeList(resultArray);
          }
        } catch {
          // Ignore scroll error
        }
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  return (
    <>
      {companyData.setWhatsAppNumber !== null &&
        companyData.setWhatsAppNumber !== '' &&
        companyData.setWhatsAppNumber.length >= 12 && (
          <ButtonWhats
            type="button"
            aria-label="search-products"
            onClick={() => {
              window.open(
                `https://wa.me/${
                  companyData.setWhatsAppNumber
                }?text=${encodeURIComponent(companyData.setWhatsAppText)}`,
                '_blank',
                'noreferrer',
              );
            }}
          >
            <BsWhatsapp size={45} color={theme.palette.primary.main} />
          </ButtonWhats>
        )}
      {companyData.setGroups === 'S' && (
        <ButtonSearch
          type="button"
          aria-label="search-products"
          onClick={() => {
            if (showSearch) {
              setProducts([]);
              setTimeout(() => {
                setProducts(backupProducts);
              }, 1);
              const lastCategory = localStorage.getItem('lastCategory');
              if (categories.length > 0) {
                setfilteredCategories([categories[Number(lastCategory)]]);
              }
              setValue(Number(lastCategory));
            }
            window.scrollTo(0, 0);
            setShowSearch(!showSearch);
          }}
        >
          <label htmlFor="search-input">
            <FiSearch size={20} color={theme.palette.primary.main} />
          </label>
        </ButtonSearch>
      )}
      <Container>
        {!showSearch && (
          <>
            {companyData.setDelivery === 'S' &&
              companyData.table === companyData.setDeliveryTable && (
                <ContainerHeader>
                  <button
                    onClick={() => {
                      setAccepted(false);
                      setShowModalStart(true);
                    }}
                    style={{ backgroundColor: 'transparent', border: 'none' }}
                  >
                    <div id="header_banner">
                      <div>
                        <img
                          src={
                            'https://sgbr.datamais.com.br/api/public/logos/logo_' +
                            `${companyData.companyCode}` +
                            '.png'
                          }
                          height="50"
                        ></img>
                        <p>{companyData.desClient}</p>
                      </div>
                    </div>
                  </button>
                </ContainerHeader>
              )}
            <ContainerTabs>
              <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={(event, val) => {
                  localStorage.setItem('lastCategory', val);
                  if (companyData.setGroups === 'S') {
                    setfilteredCategories([categories[Number(val)]]);
                    handleChangeCategories(event, Number(val));
                  } else {
                    handleChangeCategories(event, Number(val));
                  }
                }}
                aria-label="disabled tabs example"
                scrollButtons="on"
                variant="scrollable"
              >
                {categories.map(category => {
                  return (
                    <Tab
                      key={category.ID_DM003}
                      label={category.DS_GRUPO.toUpperCase()}
                      onClick={() => {
                        document
                          .getElementById(category.DS_GRUPO.toUpperCase())
                          ?.scrollIntoView({ block: 'start' });
                      }}
                      style={{
                        fontWeight: 'bold',
                        fontSize:
                          categories[value].ID_DM003 === category.ID_DM003
                            ? 16
                            : 13,
                        color:
                          categories[value].ID_DM003 !== category.ID_DM003
                            ? theme.palette.primary.main
                            : 'black',
                      }}
                    />
                  );
                })}
              </Tabs>
            </ContainerTabs>
          </>
        )}

        <Main
          style={{
            height:
              browserName == 'Mobile Safari' || browserName == 'Safari'
                ? '70vh'
                : '90vh',
          }}
          showSearch={showSearch}
        >
          {showSearch && (
            <div id="search">
              <div>
                <FiSearch size={20} color={theme.palette.primary.main} />
                <input
                  id="search-input"
                  type="text"
                  placeholder="Buscar Produtos"
                  onChange={e => {
                    if (e.target.value) {
                      const filteredItems = products.filter(item => {
                        return (
                          item.DS_ITEM.indexOf(e.target.value.toUpperCase()) >=
                          0
                        );
                      });
                      setProducts(filteredItems);
                    } else {
                      setProducts([]);
                      setTimeout(() => {
                        setProducts(backupProducts);
                      }, 1);
                    }
                  }}
                />
              </div>
            </div>
          )}
          {!showSearch
            ? companyData.setGroups === 'S'
              ? filteredCategories.map(category => {
                  const equalProducts: ProductsProps[] = [];
                  products.forEach(product => {
                    if (product.ID_DM003 === Number(category.ID_DM003)) {
                      equalProducts.push(product);
                    }
                    if (Number(category.ID_DM003) === -1) {
                      if (product.IN_FAVORITO === 'S') {
                        equalProducts.push(product);
                      }
                    }
                  });

                  return (
                    <Category
                      key={category.ID_DM003}
                      title=""
                      products={equalProducts}
                      search={showSearch}
                    />
                  );
                })
              : categories.map(category => {
                  const equalProducts: ProductsProps[] = [];
                  products.forEach(product => {
                    if (product.ID_DM003 === Number(category.ID_DM003)) {
                      equalProducts.push(product);
                    }
                    if (Number(category.ID_DM003) === -1) {
                      if (product.IN_FAVORITO === 'S') {
                        equalProducts.push(product);
                      }
                    }
                  });

                  return (
                    <Category
                      key={category.ID_DM003}
                      title={showSearch ? '' : category.DS_GRUPO.toUpperCase()}
                      products={equalProducts}
                      search={showSearch}
                    />
                  );
                })
            : categories.map(category => {
                const equalProducts: ProductsProps[] = [];
                products.forEach(product => {
                  if (product.ID_DM003 === Number(category.ID_DM003)) {
                    equalProducts.push(product);
                  }
                  if (Number(category.ID_DM003) === -1) {
                    if (product.IN_FAVORITO === 'S') {
                      equalProducts.push(product);
                    }
                  }
                });

                return (
                  <Category
                    key={category.ID_DM003}
                    title=""
                    products={equalProducts}
                    search={showSearch}
                  />
                );
              })}
          {/* {companyData.setGroups === 'S'
            ? filteredCategories.map(category => {
                const equalProducts: ProductsProps[] = [];
                products.forEach(product => {
                  if (product.ID_DM003 === Number(category.ID_DM003)) {
                    equalProducts.push(product);
                  }
                  if (Number(category.ID_DM003) === -1) {
                    if (product.IN_FAVORITO === 'S') {
                      equalProducts.push(product);
                    }
                  }
                });

                return (
                  <Category
                    key={category.ID_DM003}
                    title=""
                    products={equalProducts}
                    search={showSearch}
                  />
                );
              })
            : categories.map(category => {
                const equalProducts: ProductsProps[] = [];
                products.forEach(product => {
                  if (product.ID_DM003 === Number(category.ID_DM003)) {
                    equalProducts.push(product);
                  }
                  if (Number(category.ID_DM003) === -1) {
                    if (product.IN_FAVORITO === 'S') {
                      equalProducts.push(product);
                    }
                  }
                });

                return (
                  <Category
                    key={category.ID_DM003}
                    title={category.DS_GRUPO.toUpperCase()}
                    products={equalProducts}
                    search={showSearch}
                  />
                );
              })} */}

          {localRadius ? (
            !accepted && (
              <Modal
                visible={showModalStart}
                setVisible={setShowModalStart}
                maxWidth={45}
                maxHeight={50}
                showExit={false}
                id="modal-start"
              >
                <div id="content-modal-start">
                  {companyData.setDelivery === 'S' &&
                  companyData.table === companyData.setDeliveryTable ? (
                    <></>
                  ) : (
                    <>
                      <br />
                      <h1>{companyData.desClient}</h1>
                    </>
                  )}

                  <div className="row">
                    <div className="row">
                      {companyData.setMenu === 'S' &&
                      companyData.setMenuTable === 0 ? (
                        <>
                          <p>Cardápio</p>
                        </>
                      ) : (
                        <>
                          {companyData.setDelivery === 'S' &&
                          companyData.table === companyData.setDeliveryTable ? (
                            <ContainerDeliveryModal>
                              <div id="logo_modal">
                                <div>
                                  <img
                                    src={
                                      'https://sgbr.datamais.com.br/api/public/logos/logo_' +
                                      `${companyData.companyCode}` +
                                      '.png'
                                    }
                                    height="60"
                                  ></img>
                                  <p>{companyData.desClient}</p>
                                </div>
                              </div>
                              <div id="text_modal">
                                <div>
                                  <p>Funcionamento</p>
                                  {timeStatus.success ? (
                                    <p className="open_time">Aberto</p>
                                  ) : (
                                    <p className="close_time">Fechado</p>
                                  )}
                                </div>
                              </div>
                              <div id="text_info_modal">
                                <div>
                                  <p className="bold_text">Entrega</p>
                                  <div>
                                    <p className="bold_text">Retirada</p>
                                    <ButtonDelivery
                                      type="button"
                                      onClick={() => {
                                        if (urlGoogleMapsDelivery !== '') {
                                          window.open(
                                            urlGoogleMapsDelivery,
                                            '_blank',
                                            'noreferrer',
                                          );
                                        }
                                      }}
                                    >
                                      <FaMapMarkerAlt
                                        style={{
                                          marginTop: '1rem',
                                          color: theme.palette.primary.main,
                                        }}
                                        size={24}
                                      />
                                    </ButtonDelivery>
                                  </div>
                                </div>
                              </div>
                              <div id="text_info_modal">
                                <div>
                                  <p>{deliveryTime}</p>
                                  <p>{deliveryTimeWithDrawal}</p>
                                </div>
                              </div>
                              <div id="text_info_modal">
                                <div>
                                  <p className="bold_text">Pagamento</p>
                                  <p className="bold_text">Horários</p>
                                  <p className="bold_text">Informações</p>
                                </div>
                              </div>
                              <div id="icons_info_modal">
                                <div>
                                  <ButtonDelivery
                                    type="button"
                                    onClick={() => {
                                      setShowModalPaymentsDelivery(true);
                                    }}
                                  >
                                    <CiCreditCard2
                                      style={{
                                        color: theme.palette.primary.main,
                                      }}
                                      size={32}
                                    />
                                  </ButtonDelivery>
                                  <ButtonDelivery
                                    type="button"
                                    onClick={() => {
                                      setShowModalDeliveryHours(true);
                                    }}
                                  >
                                    <CiClock2
                                      style={{
                                        color: theme.palette.primary.main,
                                        marginRight: '3.8rem',
                                        marginLeft: '3.8rem',
                                      }}
                                      size={32}
                                    />
                                  </ButtonDelivery>
                                  <ButtonDelivery
                                    type="button"
                                    onClick={() => {
                                      if (deliveryMoreInfo !== '') {
                                        window.open(
                                          deliveryMoreInfo,
                                          '_blank',
                                          'noreferrer',
                                        );
                                      }
                                    }}
                                  >
                                    <CiCirclePlus
                                      style={{
                                        color: theme.palette.primary.main,
                                      }}
                                      size={32}
                                    />
                                  </ButtonDelivery>
                                </div>
                              </div>
                            </ContainerDeliveryModal>
                          ) : (
                            <>
                              {companyData.setAutoConference === 'S' &&
                              companyData.table !==
                                companyData.setDeliveryTable &&
                              companyData.table ===
                                companyData.setAutoConferenceTable ? (
                                <p>Faça o seu pedido!</p>
                              ) : (
                                <>
                                  <p>Mesa</p>
                                  {companyData.table < 10 ? (
                                    <span>0{companyData.table}</span>
                                  ) : (
                                    <span>{companyData.table}</span>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  {!timeStatus.success && (
                    <div style={{ textAlign: 'center' }}>
                      <p style={{ margin: '0px', fontSize: '17px' }}>
                        <strong>{timeStatus.message}</strong>
                      </p>
                    </div>
                  )}
                  <>
                    {companyData.setMenu === 'S' &&
                    companyData.setMenuTable === 0 ? (
                      <></>
                    ) : (
                      <>
                        {companyData.setCustomer !== 'N' &&
                        companyData.guestCheckPad === 0 &&
                        companyData.table !== companyData.setDeliveryTable &&
                        companyData.table !==
                          companyData.setAutoConferenceTable ? (
                          <div>
                            <p>Informe seu código de comanda ou cliente</p>
                            <Input type="number" ref={inputCustomer} />
                            <br />
                          </div>
                        ) : (
                          <></>
                        )}
                        {companyData.guestCheckPad === 1 &&
                        companyData.setPhone !== 'C' &&
                        companyData.table !== companyData.setDeliveryTable ? (
                          <div>
                            <p>Informe sua comanda</p>
                            <Input type="number" ref={inputGuestCheck} />
                            <br />
                          </div>
                        ) : (
                          <></>
                        )}
                        {companyData.setPhone !== 'N' &&
                        companyData.table !== companyData.setDeliveryTable ? (
                          <div>
                            <p>Informe seu telefone celular com DDD</p>
                            <Input type="tel" ref={inputPhone} />
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </>
                  <button
                    type="button"
                    className="button-filled proceed"
                    onClick={proceedModal}
                  >
                    Prosseguir
                  </button>

                  <div id="align-footer-delivery">
                    <div>
                      <img src={LogoDatamais} alt="Logo Datamais" />
                      <p>
                        <a
                          href="https://datamais.com.br"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Datamais
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </Modal>
            )
          ) : (
            <Modal
              visible={showModalStart}
              setVisible={setShowModalStart}
              maxWidth={40}
              maxHeight={50}
              showExit={false}
              id="modal-start"
            >
              <div id="content-modal-start">
                <h1>Bem-vindo(a)</h1>
                {companyData.setLocation === 'S' ? (
                  <>
                    <span id="accept-location">
                      Aceite o compartilhamento de sua localização para acessar
                      o cardápio. Essa informação será utilizada apenas durante
                      o uso autoatendimento.
                    </span>

                    <h2>
                      Identificamos que você não se encontra na localização do
                      estabilecimento ou não utilizou um QRCode válido.
                    </h2>
                  </>
                ) : (
                  <>
                    <br />
                    <h2>
                      Identificamos que você não utilizou um QRCode válido.
                    </h2>
                  </>
                )}

                <div id="align-footer">
                  <div>
                    <img src={LogoDatamais} alt="Logo Datamais" />
                    <p>
                      <a
                        href="https://datamais.com.br"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Datamais
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </Modal>
          )}
          {/* Modal partner password */}
          <Modal
            visible={modalPasswordPartner}
            setVisible={setModalPasswordPartner}
            maxWidth={40}
            maxHeight={50}
            showExit={false}
            id="modal-start"
          >
            <div id="content-modal-start">
              <h1>{companyData.desClient}</h1>
              <h3>Informe sua senha</h3>
              <input
                className="inputHomeCart"
                name="reference"
                type="password"
                id="passwordPartner"
              />
              <button
                type="button"
                className="button-filled proceed"
                onClick={() => {
                  validPasswordPartner();
                }}
              >
                Prosseguir
              </button>
              <div id="align-footer">
                <div>
                  <img src={LogoDatamais} alt="Logo Datamais" />
                  <p>
                    <a
                      href="https://datamais.com.br"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Datamais
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal partner balance */}
          <Modal
            visible={modalBalancePartner}
            setVisible={setModalBalancePartner}
            maxWidth={40}
            maxHeight={50}
            showExit={false}
            id="modal-start"
          >
            <div id="content-modal-start">
              <h1>{companyData.desClient}</h1>
              <h3>Saldo disponível: {balance}</h3>
              <button
                type="button"
                className="button-filled proceed"
                onClick={() => {
                  setModalBalancePartner(false);
                }}
              >
                Prosseguir
              </button>
              <div id="align-footer">
                <div>
                  <img src={LogoDatamais} alt="Logo Datamais" />
                  <p>
                    <a
                      href="https://datamais.com.br"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Datamais
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            visible={showModalPaymentsDelivery}
            setVisible={setShowModalPaymentsDelivery}
            maxWidth={40}
            maxHeight={50}
            showExit={false}
            id="modal-start"
          >
            <div id="content-modal-start">
              <h1>Formas de pagamento</h1>
              {optionsPayment.map(pay => (
                <p>{pay.DS_FORMA_PAGAMENTO}</p>
              ))}
              <button
                type="button"
                className="button-filled proceed"
                onClick={() => {
                  setShowModalPaymentsDelivery(false);
                }}
              >
                Voltar
              </button>
              <p></p>
            </div>
          </Modal>
          <Modal
            visible={showModalDeliveryHours}
            setVisible={setShowModalDeliveryHours}
            maxWidth={40}
            maxHeight={50}
            showExit={false}
            id="modal-start2"
          >
            <div id="content-modal-start">
              <h1>Horários</h1>
              {deliveryTimeList.map(item => (
                <p>{item}</p>
              ))}
              <button
                type="button"
                className="button-filled proceed"
                onClick={() => {
                  setShowModalDeliveryHours(false);
                }}
              >
                Voltar
              </button>
              <p></p>
            </div>
          </Modal>
          {adBanner ? (
            <BannerModal
              visible={adBanner}
              setVisible={setAdBanner}
              maxWidth={95}
              maxHeight={80}
              showExit
              id="modal-ad"
            >
              <div id="content-modal-start">
                <div id="container-image-product">
                  <img
                    src="https://datamais.com.br/img-autoatendimento/228/banner.png"
                    alt=""
                  />
                </div>
              </div>
            </BannerModal>
          ) : (
            <></>
          )}
        </Main>
      </Container>
    </>
  );
};

export default Home;
