/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-prototype-builtins */
import { useEffect, useState, useCallback, useRef } from 'react';
import Lottie from 'react-lottie';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { formatCurrencyBRL, maskBRL } from 'utils/formatCurrencyBRL';
import { maskCPF, maskCNPJ } from 'utils/formatCpfCnpj';
import { maskPhone } from 'utils/formatPhone';
import { cpfCnpjValidation } from 'utils/cpfCnpjValidation';
import SpecificApiLocalClient from 'services/api';
import apiAutoatendimento from 'services/apiAutoatendimento';
import { checkPromotion } from 'utils/checkPromotion';
import { getStorage, setStorage } from 'services/storage';

import { useCart } from 'hooks/useCart';
import { useRoutes } from 'hooks/useRoutes';
import { useInformationsClient } from 'hooks/useInformationsClient';

import ProductCart from 'components/ProductCart';
import AddressCard from 'components/AddressCard';
import Modal from 'components/Modal';
import { optionPaymentProps } from 'models/paymentTypeOptions';
import { CustomerInfoProps, ProductsCartProps, AddressCardProps } from 'models';

import Order from 'assets/animations/order.json';
import SmallShopping from 'assets/animations/small-shop.json';

import { DeliveryFeeControll } from '../../enums/delivery-fee-controll';
import {
  AnimationContainer,
  ContentCenter,
  Container,
  ContainerLoading,
} from './styles';
import { CopyPixKeyToClipboard } from './components/CopyPixKeyToClipboard';
import { SendReceiptToWhatsapp } from './components/SendReceiptToWhatsapp';

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: SmallShopping,
};

const orderAnimation = {
  loop: false,
  autoplay: true,
  animationData: Order,
};
const smallShoppingAnimation = {
  loop: false,
  autoplay: true,
  animationData: SmallShopping,
};

interface optionsPaymentProps {
  optionPayment: optionPaymentProps[];
}

const Cart: React.FC = () => {
  const { companyData } = useInformationsClient();
  const history = useHistory();
  const { updateRoute } = useRoutes();

  if (companyData.companyCode === 0) {
    updateRoute('/');
    history.push('/');
  }

  const { cart, setCart, renderProducts, handleDeleteProductInCart } =
    useCart();
  const api = SpecificApiLocalClient();

  const [totalPriceCartDelivery, setTotalPriceCartDelivery] = useState(0);
  const [totalPriceCart, setTotalPriceCart] = useState(0);
  const [selecteDeliveryTax, setSelectedDeliveryTax] = useState(0);
  const [showModalFinishedOrder, setShowModalFinishedOrder] = useState(false);
  const [showModalAddress, setShowModalAddress] = useState(false);
  const [showModalOrder, setShowModalOrder] = useState(false);
  const [showModalOrderDelivery, setShowModalOrderDelivery] = useState(false);
  const [showModalSignUp, setShowModalSignUp] = useState(false);
  const [showModalValidation, setShowModalValidation] = useState(false);
  const [showModalUpdateProfile, setShowModalUpdateProfile] = useState(false);
  const [showLastOrder, setShowLastOrder] = useState(false);
  const [showButtonLastOrder, setButtonLastOrder] = useState(false);
  const [showDeleteAddress, setShowDeleteAddress] = useState(false);
  const [showNewAddress, setShowNewAddress] = useState(false);
  const [orderInProgress, setOrderInProgress] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isLoadingType, setisLoadingType] = useState('order');
  const [isLoadingText, setisLoadingText] = useState('Aguarde...');

  /* Delivery */
  const [currentAddressDesc, setCurrentAddressDesc] = useState('');
  const [currentAddressID, setCurrentAddressID] = useState('');
  const [descAddress, setDescAddress] = useState('');
  const [cidadeDelivery, setCidadeDelivery] = useState('');
  const [bairroDelivery, setBairroDelivery] = useState('');
  const [ruaDelivery, setRuaDelivery] = useState('');
  const [complementoDelivery, setComplementoDelivery] = useState('');
  const [numeroDelivery, setNumeroDelivery] = useState('');
  const [optionCidadeDelivery, setOptionCidadeDelivery] = useState<
    string | null
  >('');
  const [optionBairroDelivery, setOptionBairroDelivery] = useState<
    string | null
  >('');
  const [optionRuaDelivery, setOptionRuaDelivery] = useState<string | null>('');
  const [optionsNeighbor, setOptionsNeighbor] = useState(['']);
  const [optionsCity, setOptionsCity] = useState(['']);
  const [city, setCity] = useState([]);
  const [neighbor, setNeighbor] = useState([]);
  const [optionsStreet, setOptionsStreet] = useState(['']);
  const [street, setStreet] = useState([]);
  const [addressList, setaddressList] = useState([]);
  const [idAddressSelect, setIdAddressSelect] = useState('');
  const [idDm080, setIdDm080] = useState(0);
  const [addressComplement, setAddressComplement] = useState('');
  const [addressNumber, setAddressNumber] = useState('');
  const [addressType, setAddressType] = useState('');
  const [optionsPayment, setOptionsPayment] = useState<optionPaymentProps[]>(
    [],
  );
  const [selectedPayment, setSelectedPayment] = useState(0);
  const [customerId, setCustomerId] = useState(0);

  // prettier-ignore
  const [addressListSelect, setaddressListSelect] = useState([{ 'desc_select_address': '', 'vl_select_address': '' }]);

  const [moneyChange, setMoneyChange] = useState('');
  const [controllMChange, setControllMChange] = useState(false);

  const [clientName, setClientName] = useState('');
  const [clientCpf, setClientCpf] = useState('');
  const [clientPhone, setClientPhone] = useState('');
  const [clientMail, setClientMail] = useState('');
  const [jsonNewCustomerData, setJsonNewCustomerData] =
    useState<CustomerInfoProps>();
  const [jsonCustomerData, setJsonCustomerData] = useState<CustomerInfoProps>();
  const [controllFee, setControllFee] = useState(true);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [timeStatus, setTimeStatus] = useState({} as any);
  const [taxaControllRegisterAddress, setTaxaControllRegisterAddress] =
    useState(getStorage('taxaControll'));

  const returnDefaultAddress = useCallback(() => {
    const deskAddress = {
      type: 'retirada',
      descAddress: 'Retirada balcão',
      city: [{ CD_CIDADE: 0, NM_CIDADE: '', ID_DM025: 0 }],
      neighbor: [{ ID_DM081: 0, NM_BAIRRO: '', CD_BAIRRO: 0 }],
      street: [
        {
          ID_DM080: 0,
          CD_LOGRADOURO: 0,
          NM_TIPO: 'Retirada balcão',
          NM_LOGRADOURO: 'Retirada balcão',
          NR_LOGRADOURO: 0,
          DS_COMPLEMENTO: 'Retirada balcão',
          NR_CEP: '',
          DS_LOCALIZACAO: 'Retirada balcão',
          VL_ENTREGA: 0,
        },
      ],
      numeroDelivery: '0',
      complementoDelivery: 'Retirada balcão',
    };
    const localAddress = {
      type: 'consumir',
      descAddress: 'Consumir no local',
      city: [{ CD_CIDADE: 0, NM_CIDADE: '', ID_DM025: 0 }],
      neighbor: [{ ID_DM081: 0, NM_BAIRRO: '', CD_BAIRRO: 0 }],
      street: [
        {
          ID_DM080: 0,
          CD_LOGRADOURO: 0,
          NM_TIPO: 'Consumir no local',
          NM_LOGRADOURO: '',
          NR_LOGRADOURO: 0,
          DS_COMPLEMENTO: 'Consumir no local',
          NR_CEP: '',
          DS_LOCALIZACAO: 'Consumir no local',
          VL_ENTREGA: 0,
        },
      ],
      numeroDelivery: '0',
      complementoDelivery: 'Consumir no local',
    };

    switch (companyData.setGetLocalDelivery) {
      case 'T':
        return [deskAddress, localAddress];
      case 'B':
        return [deskAddress];
      case 'C':
        return [localAddress];
      default:
        return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calcTotalPriceCart = useCallback(() => {
    const totalValue = cart.reduce(
      (accum: number, curr: ProductsCartProps) => accum + Number(curr.subtotal),
      0,
    );
    setTotalPriceCart(totalValue);
    return totalValue;
  }, [cart]);

  const calcTotalPriceCartDelivery = useCallback(() => {
    const totalValue = cart.reduce(
      (accum: number, curr: ProductsCartProps) => accum + Number(curr.subtotal),
      0,
    );
    const deliveryTax = selecteDeliveryTax;
    setTotalPriceCartDelivery(totalValue + deliveryTax);
    return totalValue;
  }, [cart, selecteDeliveryTax]);

  useEffect(() => {
    renderProducts();
  }, [renderProducts]);

  useEffect(() => {
    calcTotalPriceCart();
    calcTotalPriceCartDelivery();
  }, [calcTotalPriceCart, calcTotalPriceCartDelivery]);

  useEffect(() => {
    let desc_select_address = '';
    let vl_select_address = '';
    if (idAddressSelect !== undefined) {
      const index = parseInt(idAddressSelect, 10);
      if (
        !Number.isNaN(index) &&
        index >= 0 &&
        index < addressList.length &&
        addressList[index]
      ) {
        desc_select_address = addressList[index].descAddress;
        vl_select_address = formatCurrencyBRL(
          addressList[index].street[0].VL_ENTREGA,
        );
        setSelectedDeliveryTax(Number(addressList[index].street[0].VL_ENTREGA));
        setIdDm080(addressList[index].street[0].ID_DM080);
        setAddressNumber(addressList[index].numeroDelivery);
        setAddressComplement(addressList[index].complementoDelivery);
        setAddressType(addressList[index].type);
      }
    }
    const data_address = {
      desc_select_address,
      vl_select_address,
    };

    const FeeControll = getStorage('taxaControll');
    if (FeeControll === 'D') {
      const DataFee = JSON.parse(getStorage('dataTaxas'));
      setControllFee(true);
      if (DataFee && idAddressSelect) {
        const addressIndex = parseInt(idAddressSelect, 10);
        if (addressList[addressIndex].type === 'address') {
          DataFee.ds_chave_api_google_maps =
            DataFee.ds_chave_api_google_maps.replaceAll(' ', '');
          DataFee.total_pedido = totalPriceCart;
          DataFee.ds_endereco_destino = `Rua ${
            Array.isArray(addressList[addressIndex].street) &&
            addressList[addressIndex].street[0] &&
            addressList[addressIndex].street[0].DS_LOCALIZACAO
              ? addressList[addressIndex].street[0].DS_LOCALIZACAO
              : addressList[addressIndex].street
          }, ${addressList[addressIndex].numeroDelivery}, ${
            Array.isArray(addressList[addressIndex].neighbor) &&
            addressList[addressIndex].neighbor[0] &&
            addressList[addressIndex].neighbor[0].NM_BAIRRO
              ? addressList[addressIndex].neighbor[0].NM_BAIRRO
              : addressList[addressIndex].neighbor
          }, ${
            Array.isArray(addressList[addressIndex].city) &&
            addressList[addressIndex].city[0] &&
            addressList[addressIndex].city[0].NM_CIDADE
              ? addressList[addressIndex].city[0].NM_CIDADE
              : addressList[addressIndex].city
          }, ${
            Array.isArray(addressList[addressIndex].street) &&
            addressList[addressIndex].street[0] &&
            addressList[addressIndex].street[0].NR_CEP
              ? addressList[addressIndex].street[0].NR_CEP
              : ''
          }`;
          apiAutoatendimento
            .post(`/distance_order_calc/`, DataFee)
            .then(distance_val => {
              if (distance_val.data.status !== 'error') {
                setSelectedDeliveryTax(distance_val.data.taxa_entrega);
                data_address.vl_select_address = formatCurrencyBRL(
                  distance_val.data.taxa_entrega,
                );
                setaddressListSelect([data_address]);
              } else {
                toast.error('Não atendemos nesse endereço!');
                setOrderInProgress(true);
                setControllFee(false);
              }
            })
            .catch(() => {
              setaddressListSelect([data_address]);
              console.log('error');
            });
        } else {
          setSelectedDeliveryTax(0);
          data_address.vl_select_address = formatCurrencyBRL(0);
          setaddressListSelect([data_address]);
        }
      } else {
        setaddressListSelect([data_address]);
      }
    } else {
      setaddressListSelect([data_address]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idAddressSelect]);

  const returnLastOrder = () => {
    const lastCart = getStorage('lastCart');
    if (lastCart !== null && lastCart !== '""') {
      const stringfiedLastProducts: ProductsCartProps[] = JSON.parse(lastCart);
      const holdCurrentCommand = getStorage('command');
      const currentTableConvert = getStorage('table');
      const holdCompanyCode = getStorage('companyCode');
      const companyCode = holdCompanyCode.replace(/(^")|("$)/g, '');
      const holdLastCompanyCode = getStorage('lastCompanyCode');
      const lastCompanyCode = holdLastCompanyCode.replace(/(^")|("$)/g, '');
      const holdCellPhone = getStorage('cellPhone');
      const cellPhone = holdCellPhone.replace(/(^")|("$)/g, '');
      const currentTable = currentTableConvert.replace(/(^")|("$)/g, '');
      const currentCommand = parseInt(holdCurrentCommand, 10);
      if (lastCompanyCode === companyCode) {
        stringfiedLastProducts[0].NrLocal = currentTable;
        stringfiedLastProducts[0].NrConta = currentCommand;
        stringfiedLastProducts[0].NrFone = cellPhone;
        setStorage('lastCart', stringfiedLastProducts);
        setButtonLastOrder(true);
      } else {
        localStorage.removeItem('lastCart');
        localStorage.removeItem('lastCompanyCode');
        localStorage.removeItem('lastCellPhone');
      }
    }
  };

  useEffect(() => {
    returnLastOrder();
  }, []);

  const closeCommand = useCallback(() => {
    if (
      companyData.setAutoConference === 'S' &&
      companyData.setAutoConferenceTable === companyData.table
    ) {
      api
        .get(
          `/fprg_ImprimirConferenciaJSON/${companyData.command}/${companyData.printerCode}/${companyData.companyCode}/0/`,
        )
        .then(response => {
          console.log(response);
          setStorage('cart', '');
          setStorage('lastCart', '');
          setStorage('lastCategory', 0);
        });
    }
  }, [
    api,
    companyData.command,
    companyData.companyCode,
    companyData.printerCode,
    companyData.setAutoConference,
    companyData.setAutoConferenceTable,
    companyData.table,
  ]);

  const successOrder = useCallback(() => {
    setShowModalFinishedOrder(true);
    toast.success('Pedido realizado com sucesso!', {
      onClose: () => setShowModalFinishedOrder(false),
    });
    setShowModalOrder(false);
    setStorage('cart', []);
    setCart([]);
    updateRoute('/');
    history.push('/');
    closeCommand();
  }, [closeCommand, history, setCart, updateRoute]);

  const orderError = useCallback(() => {
    toast.error('Erro ao realizar pedido!');
    setShowModalOrder(false);
    setisLoading(false);
    updateRoute('/');
    history.push('/');
  }, [history, updateRoute]);

  const postApiOrder = useCallback(() => {
    const idUserStorage = getStorage('id_user');
    const orderJson = {
      insert_order: {
        id_dm000: companyData.companyCode,
        id_dm011: companyData.attendantCode,
        valor_total: totalPriceCart,
        nr_conta: idUserStorage,
        nr_mesa: companyData.table,
        nr_fone: companyData.cellPhone,
        in_tipo: companyData.table === companyData.setDeliveryTable ? 'D' : 'A',
      },
    };
    apiAutoatendimento.post('insert_order', orderJson);
  }, [
    companyData.attendantCode,
    companyData.cellPhone,
    companyData.companyCode,
    companyData.setDeliveryTable,
    companyData.table,
    totalPriceCart,
  ]);

  const orderReturnValidation = useCallback(
    answer => {
      if (answer.data.result[0][0].situacao === 'erro') {
        api
          .get(`/fprg_GetProdutosJSON_AA/${companyData.companyCode}/`)
          .then(response => {
            const data = response.data.result[0];
            const itensError = answer.data.result[0][0].itens;
            itensError.forEach(element => {
              toast.warning(
                `${String(element.DS_MENSAGEM).toUpperCase()} DE ${
                  data.find(e => {
                    return e.ID_DM004 === element.ID_DM004;
                  }).DS_ITEM
                }`,
              );
            });
            setisLoading(false);
            setShowModalOrder(false);
            setOrderInProgress(false);
          })
          .catch(error => {
            console.log(error);
            toast.error('Erro ao validar promoções!');
          });
      } else {
        successOrder();
        postApiOrder();
      }
    },
    [api, companyData.companyCode, postApiOrder, successOrder],
  );

  const orderNow = useCallback(
    (query: { _parameters: (number | ProductsCartProps[][])[] }) => {
      api
        .get(
          `/fprg_IncluirContaVerificarPessoaJSON/${companyData.command}/${companyData.table}/${companyData.companyCode}/1/0/`,
        )
        .then(result => {
          const prjResult = String(result.data.result[0].msg);
          if (prjResult.includes('Conta já conferida!')) {
            toast.warning(result.data.result[0].msg, {
              autoClose: 8000,
              onClose: () => setShowModalFinishedOrder(false),
            });
            setShowModalOrder(false);
            updateRoute('/');
            history.push('/');
          } else if (String(companyData.command).length === 9) {
            api
              .post(
                `/%22fprg_FinalizarPedido%22/${companyData.companyCode}/${companyData.attendantCode}/AUTOATENDIMENTO/false/0/0/`,
                query,
              )
              .then(answer => {
                orderReturnValidation(answer);
              })
              .catch(() => {
                orderError();
              });
          } else if (result.data.result[0].result === false) {
            if (companyData.guestCheckPad !== 1) {
              api
                .post(
                  `/%22fprg_FinalizarPedido%22/${companyData.companyCode}/${companyData.attendantCode}/AUTOATENDIMENTO/false/0/0/`,
                  query,
                )
                .then(answer => {
                  orderReturnValidation(answer);
                })
                .catch(() => {
                  orderError();
                });
            } else {
              toast.warning(result.data.result[0].msg, {
                autoClose: 8000,
                onClose: () => setShowModalFinishedOrder(false),
              });
              setShowModalOrder(false);
              setOrderInProgress(false);
              updateRoute('/');
              history.push('/');
            }
          } else {
            api
              .post(
                `/%22fprg_FinalizarPedido%22/${companyData.companyCode}/${companyData.attendantCode}/AUTOATENDIMENTO/false/0/0/`,
                query,
              )
              .then(answer => {
                orderReturnValidation(answer);
              })
              .catch(() => {
                orderError();
              });
          }
        });
    },
    [
      api,
      companyData.attendantCode,
      companyData.command,
      companyData.companyCode,
      companyData.guestCheckPad,
      companyData.table,
      history,
      orderError,
      orderReturnValidation,
      updateRoute,
    ],
  );

  const handleSendFinishedOrder = useCallback(() => {
    const idUserStorage = getStorage('id_user');
    setOrderInProgress(true);
    const productsInArrays = cart.map(product => {
      return [product];
    });
    const dm206List = [];
    productsInArrays.forEach(val => {
      if (val[0].ID_DM206 !== undefined && val[0].ID_DM206 > 0) {
        dm206List.push(val[0].ID_DM004);
      }
    });

    api
      .get(`/fprg_GetProdutosJSON_AA/${companyData.companyCode}/`)
      .then(response => {
        let data = response.data.result[0];
        data = data.filter(val => {
          return dm206List.includes(Number(val.ID_DM004));
        });
        const outOfStock = [];
        data.forEach(element => {
          const [sale_promotion, sale_amount, promotion_value] =
            checkPromotion(element);
          if (!sale_promotion || !sale_amount) {
            outOfStock.push(element);
          }
        });
        if (outOfStock.length) {
          setisLoading(false);
          setShowModalOrder(false);
          setOrderInProgress(false);
          outOfStock.forEach(element => {
            toast.warning(`O ITEM ${element.DS_ITEM} NÃO ESTÁ MAIS DISPONÍVEL`);
          });
        } else {
          let query: { _parameters: (number | ProductsCartProps[][])[] };
          if (idUserStorage) {
            const idUser = Number(JSON.parse(idUserStorage));

            query = {
              _parameters: [productsInArrays, idUser],
            };
          } else {
            query = {
              _parameters: [productsInArrays, 0],
            };
          }
          orderNow(query);
        }
      })
      .catch(error => {
        console.log(error);
        toast.error('Erro ao validar promoções!');
      });
  }, [api, cart, companyData.companyCode, orderNow]);

  const handleConfirmOrder = useCallback(() => {
    setisLoading(true);
    const holdCart = getStorage('cart');
    const saveLast: ProductsCartProps[] = JSON.parse(holdCart);
    setStorage('lastCart', saveLast);
    const holdCompanyCode = getStorage('companyCode');
    // eslint-disable-next-line prettier/prettier
    const lastCompanyCode = holdCompanyCode.replace(/(^")|("$)/g, '');
    setStorage('lastCompanyCode', lastCompanyCode);
    const holdCellPhone = getStorage('cellPhone');
    // eslint-disable-next-line prettier/prettier
    const lastCellPhone = holdCellPhone.replace(/(^")|("$)/g, '');
    setStorage('lastCellPhone', lastCellPhone);
    handleSendFinishedOrder();
  }, [handleSendFinishedOrder]);

  const handleUpdateCustomer = useCallback(() => {
    if (jsonCustomerData !== undefined && jsonNewCustomerData !== undefined) {
      setisLoadingType('store');
      setisLoadingText('Atualizando dados cadastrais...');
      setisLoading(true);
      const newJsonCustomerData: CustomerInfoProps = {
        ID_DM001: jsonCustomerData.ID_DM001,
        NM_RAZAO_SOCIAL: jsonNewCustomerData.NM_RAZAO_SOCIAL.toUpperCase(),
        NR_CNPJ: jsonNewCustomerData.NR_CNPJ,
        NR_FONE: jsonNewCustomerData.NR_FONE,
        DS_EMAIL: jsonNewCustomerData.DS_EMAIL,
        ID_DM080: 0,
        DS_COMPLEMENTO: '',
        NR_LOGRADOURO: '',
      };
      api
        .post(`/%22fprg_ClienteJSON%22/${companyData.companyCode}`, [
          newJsonCustomerData,
        ])
        .then(answer => {
          setisLoading(false);
          const apiPostResponse = answer.data.result[0];
          if (apiPostResponse.length > 0) {
            setCustomerId(jsonCustomerData.ID_DM001);
            setStorage('clientInfo', newJsonCustomerData);
            toast.success('Cadastro atualizado com sucesso!');
            setShowModalUpdateProfile(false);
            setShowModalOrderDelivery(true);
          } else {
            setShowModalValidation(false);
            setShowModalOrderDelivery(true);
            toast.error('Erro ao efetuar cadastro!');
          }
        })
        .catch(e => {
          // setShowModalValidation(false);
          console.log(e);
          toast.error('Erro ao efetuar cadastro!');
        });
    }
  }, [api, companyData.companyCode, jsonCustomerData, jsonNewCustomerData]);

  const handleSuccess = useCallback(
    result => {
      setisLoadingType('store');
      setisLoadingText(result);
      setisLoading(true);
      // toast.success(result);
      const dataCpfCnpj = clientCpf
        .replaceAll('.', '')
        .replaceAll('-', '')
        .replaceAll('/', '');
      const dataPhone = clientPhone
        .replace('(', '')
        .replace(')', '')
        .replaceAll(' ', '')
        .replaceAll('-', '');
      const dataMail = clientMail.toLowerCase();
      api
        .get(`/fprg_GetClienteJSON/${companyData.companyCode}/${dataPhone}`)
        .then(response => {
          setisLoading(false);
          const apiResponse = response.data.result[0];
          const dataResponse = apiResponse.filter(
            // eslint-disable-next-line no-shadow
            e => e.ID_DM001 === Math.max(...apiResponse.map(e => e.ID_DM001)),
          );
          const jsonCustomer = {
            ID_DM001: 0,
            NM_RAZAO_SOCIAL: clientName.toUpperCase(),
            NR_CNPJ: dataCpfCnpj,
            NR_FONE: dataPhone,
            DS_EMAIL: dataMail,
            ID_DM080: 0,
            DS_COMPLEMENTO: '',
            NR_LOGRADOURO: '',
          };
          // force to post new customer
          if (dataResponse.length > 0) {
            if (
              dataResponse[0].NR_CNPJ !== jsonCustomer.NR_CNPJ ||
              dataResponse[0].NR_FONE !== jsonCustomer.NR_FONE ||
              dataResponse[0].DS_EMAIL !== jsonCustomer.DS_EMAIL
            ) {
              setJsonCustomerData(dataResponse[0]);
              setJsonNewCustomerData(jsonCustomer);
              setShowModalValidation(false);
              // setShowModalUpdateProfile(true);
              setisLoadingType('store');
              setisLoadingText('Enviando dados de cadastro...');
              setisLoading(true);
              const newJsonCustomerData: CustomerInfoProps = {
                ID_DM001: dataResponse[0].ID_DM001,
                NM_RAZAO_SOCIAL: jsonCustomer.NM_RAZAO_SOCIAL.toUpperCase(),
                NR_CNPJ: jsonCustomer.NR_CNPJ,
                NR_FONE: jsonCustomer.NR_FONE,
                DS_EMAIL: jsonCustomer.DS_EMAIL,
                ID_DM080: 0,
                DS_COMPLEMENTO: '',
                NR_LOGRADOURO: '',
              };
              api
                .post(`/%22fprg_ClienteJSON%22/${companyData.companyCode}`, [
                  newJsonCustomerData,
                ])
                .then(answer => {
                  setisLoading(false);
                  const apiPostResponse = answer.data.result[0];
                  if (apiPostResponse.length > 0) {
                    setCustomerId(dataResponse[0].ID_DM001);
                    setStorage('clientInfo', newJsonCustomerData);
                    toast.success('Cadastro efetuado com sucesso!');
                    setShowModalUpdateProfile(false);
                    setShowModalOrderDelivery(true);
                  } else {
                    setShowModalValidation(false);
                    setShowModalOrderDelivery(true);
                    toast.error('Erro ao efetuar cadastro!');
                  }
                })
                .catch(e => {
                  console.log(e);
                  toast.error('Erro ao efetuar cadastro!');
                });
            } else {
              jsonCustomer.ID_DM001 = dataResponse[0].ID_DM001;
              setCustomerId(dataResponse[0].ID_DM001);
              setStorage('clientInfo', jsonCustomer);
              setShowModalValidation(false);
              setShowModalOrderDelivery(true);
              toast.success('Cadastro realizado com sucesso!');
            }
          } else {
            setisLoadingText('Enviando dados...');
            setisLoading(true);
            api
              .post(`/%22fprg_ClienteJSON%22/${companyData.companyCode}`, [
                jsonCustomer,
              ])
              .then(answer => {
                setisLoading(false);
                const apiPostResponse = answer.data.result[0];
                if (apiPostResponse.length > 0) {
                  const dataIdPost = apiPostResponse[0].ID;
                  jsonCustomer.ID_DM001 = dataIdPost;
                  setCustomerId(Number(dataIdPost));
                  setStorage('clientInfo', jsonCustomer);
                  setShowModalValidation(false);
                  setShowModalOrderDelivery(true);
                  toast.success('Cadastro realizado com sucesso!');
                } else {
                  setShowModalValidation(false);
                  setShowModalOrderDelivery(true);
                  toast.error('Erro ao efetuar cadastro!');
                }
              })
              .catch(e => {
                setShowModalValidation(false);
                setShowModalOrderDelivery(true);
                console.log(e);
                toast.error('Erro ao efetuar cadastro!');
              });
          }

          setShowModalValidation(false);
          setShowModalOrderDelivery(true);
        })
        .catch(e => {
          setShowModalValidation(false);
          setShowModalOrderDelivery(true);
          console.log(e);
          toast.error('Erro ao validar dados cadastrais!');
        });
    },
    [
      api,
      clientCpf,
      clientMail,
      clientName,
      clientPhone,
      companyData.companyCode,
    ],
  );
  /*
  const handleMailCode = useCallback(() => {
    setShowModalSignUp(false);
    setisLoadingType('store');
    setisLoadingText('Enviando dados...');
    setisLoading(true);
    if (mailCode !== '') {
      const valMailCode = {
        send_mail: {
          code: Number(mailCode),
          to: clientMail,
        },
      };
      apiAutoatendimento
        .post('email_val.php', valMailCode)
        .then(response => {
          const resData = response.data.response;
          setisLoading(false);
          if (resData.code) {
            setMailCode('');
            handleSuccess(resData.default_msg);
          } else {
            setMailCode('');
            toast.error(
              <div>
                Código inválido!
                <br />
                Verifique o código enviado em seu e-mail!
              </div>,
            );
          }
        })
        .catch(error => {
          console.log(error);
          toast.error('Erro ao enviar dados!');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientMail, mailCode]);
  */

  const handleValidation = useCallback(() => {
    if (clientName === '' || clientName.length < 3) {
      toast.error('Digite seu nome!');
    } else if (clientPhone === '') {
      toast.error('Digite seu telefone!');
    } else if (clientPhone.length < 14) {
      toast.error('Telefone inválido!');
    } else if (clientCpf !== '' && !cpfCnpjValidation(clientCpf)) {
      toast.error('CPF ou CNPJ inválido!');
    } else {
      setShowModalSignUp(false);
      handleSuccess('Enviando dados de cadastro!');
    }
  }, [clientName, clientPhone, clientCpf, handleSuccess]);

  const jsonKeysToLowerCase = obj => {
    if (obj.length > 0) {
      let dataStr = JSON.stringify(obj);
      const keys = Object.keys(obj[0]);
      keys.forEach(e => {
        dataStr = dataStr.replace(
          new RegExp(`"${e}":`, 'g'),
          `"${e.toLowerCase()}":`,
        );
      });
      return JSON.parse(dataStr);
    }
    return [];
  };

  const renameKeyInJson = (json, keys) => {
    let dataStr = JSON.stringify(json);
    keys.forEach(e => {
      dataStr = dataStr.replace(
        new RegExp(`"${e.oldKey}":`, 'g'),
        `"${e.newKey}":`,
      );
    });
    return JSON.parse(dataStr);
  };

  const sendDeliveryOrder = useCallback(() => {
    const jsonOrderItens = cart.map(val => {
      const tmpComplements = renameKeyInJson(
        jsonKeysToLowerCase(val.complementos),
        [{ oldKey: 'vl_venda', newKey: 'vl_venda_complemento' }],
      );
      return {
        id_dm206: val.id_dm206,
        id_dm003: val.ID_DM003,
        id_dm004: val.id_dm004,
        cd_item: val.CD_ITEM,
        ds_item: val.DS_ITEM,
        agrupamento: val.AGRUPAMENTO,
        ds_item_reduzido: val.DS_ITEM_REDUZIDO,
        vl_venda_item: val.vl_venda_item,
        ds_cor: val.DS_COR,
        id_dm070: val.ID_DM070,
        cd_barra: val.CD_BARRA,
        qt_sabor: val.QT_SABOR,
        in_favorito: val.IN_FAVORITO,
        in_preparo: val.IN_PREPARO,
        in_gerar_item_sabor: val.IN_GERAR_ITEM_SABOR,
        qt_venda_item: val.qt_venda_item,
        complementos: tmpComplements,
        sabores: jsonKeysToLowerCase(val.sabores),
      };
    });
    let addressOrderType = 'E';
    if (addressType === 'retirada') {
      addressOrderType = 'B';
    } else if (addressType === 'consumir') {
      addressOrderType = 'C';
    }
    // taxaControllRegisterAddress !== 'D'
    const jsonOrderData = {
      nrconta: cart[0].NrConta,
      nrlocal: cart[0].NrLocal,
      idcliente: customerId,
      id_dm010: Number(optionsPayment[selectedPayment].ID_DM010),
      id_dm080:
        taxaControllRegisterAddress !== DeliveryFeeControll.DISTANCE
          ? idDm080
          : 0,
      ds_complemento: addressComplement,
      nr_logradouro: addressNumber,
      vl_troco: Number(
        moneyChange.replace('R$', '').replaceAll(' ', '').replaceAll(',', '.'),
      ),
      in_destino: addressOrderType,
      vl_entrega: selecteDeliveryTax,
      vl_total: totalPriceCartDelivery,
      itens: jsonOrderItens,
      nm_logradouro:
        taxaControllRegisterAddress === DeliveryFeeControll.DISTANCE
          ? addressList[idAddressSelect].street
          : '',
      nm_bairro:
        taxaControllRegisterAddress === DeliveryFeeControll.DISTANCE
          ? addressList[idAddressSelect].neighbor
          : '',
      nm_cidade:
        taxaControllRegisterAddress === DeliveryFeeControll.DISTANCE
          ? addressList[idAddressSelect].city
          : '',
    };
    const jsonOrderDelivery = {
      _parameters: [[jsonOrderData], 0],
    };

    setisLoadingType('store');
    setisLoadingText('Enviando pedido...');
    setisLoading(true);
    api
      .post(
        `/%22fprg_IncluirPedidoDeliveryAA%22/${companyData.companyCode}/${companyData.attendantCode}/AA-DELIVERY/false/0/0`,
        jsonOrderDelivery,
      )
      .then(answer => {
        const apiPostResponse = answer.data.result[0][0];
        if (apiPostResponse.situacao === 'ok') {
          setisLoading(true);
          setStorage('cart', []);
          setCart([]);
          setStorage('deliveryPhone', clientPhone);
          setOrderInProgress(false);
          updateRoute('/profile/last-orders');
          history.push('/profile/last-orders');
          setShowModalOrderDelivery(false);
          toast.success('Pedido enviado com sucesso!');
          postApiOrder();
        } else {
          toast.error('Erro ao enviar o pedido, tente novamente!');
          setShowModalOrderDelivery(false);
        }
      })
      .catch(e => {
        console.log(e);
        setOrderInProgress(false);
        toast.error('Erro ao enviar pedido!');
      });
  }, [
    addressComplement,
    addressList,
    addressNumber,
    addressType,
    api,
    cart,
    clientPhone,
    companyData.attendantCode,
    companyData.companyCode,
    customerId,
    history,
    idAddressSelect,
    idDm080,
    moneyChange,
    optionsPayment,
    postApiOrder,
    selecteDeliveryTax,
    selectedPayment,
    setCart,
    taxaControllRegisterAddress,
    totalPriceCartDelivery,
    updateRoute,
  ]);

  const handleConfirmOrderDelivery = useCallback(() => {
    setShowModalOrder(false);
    const storageClientInfo = getStorage('clientInfo');
    if (storageClientInfo !== null && storageClientInfo.length > 2) {
      const clientInfo = JSON.parse(getStorage('clientInfo'));
      if (
        clientInfo.hasOwnProperty('ID_DM001') &&
        clientInfo.hasOwnProperty('NR_FONE') &&
        clientInfo.NR_FONE !== 0 &&
        clientInfo.NR_FONE !== '' &&
        clientInfo.ID_DM001 !== 0 &&
        clientInfo.ID_DM001 !== ''
      ) {
        setShowModalOrderDelivery(true);
      } else {
        setShowModalSignUp(true);
      }
    } else {
      setShowModalSignUp(true);
    }
  }, []);

  const handleFinishedOrder = useCallback(() => {
    if (cart.length > 0) {
      if (
        companyData.setDelivery === 'S' &&
        companyData.table === companyData.setDeliveryTable
      ) {
        setShowModalAddress(true);
      } else {
        setShowModalOrder(true);
      }
    } else {
      toast.warn('Não há produtos em seu carrinho!');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart.length]);

  const loadingLastCart = () => {
    const lastCart = getStorage('lastCart');
    const stringfiedLastProducts: ProductsCartProps[] = JSON.parse(lastCart);
    setCart(stringfiedLastProducts);
    setStorage('cart', stringfiedLastProducts);
    setShowLastOrder(false);
    document.getElementById('lastCart').style.backgroundColor = '#DCDCDC';
    document.getElementById('lastCart').style.cursor = 'not-allowed';
  };

  const deleteAddress = (id: string, desc: string) => {
    setCurrentAddressID(id);
    setCurrentAddressDesc(desc);
    setShowDeleteAddress(true);
  };

  const deleteAddressStorage = () => {
    setShowDeleteAddress(false);
    let storageAddress = [];
    const temp_array = [];
    addressList.forEach((element, index) => {
      if (index !== parseInt(currentAddressID, 10)) {
        temp_array[index] = element;
      }
    });
    storageAddress = temp_array.filter(addr => addr !== null);
    storageAddress = temp_array.filter(
      addr => addr.type !== 'retirada' && addr.type !== 'consumir',
    );
    setStorage('address_list', storageAddress);
    setaddressList([...returnDefaultAddress(), ...storageAddress]);
    toast.success('Endereço excluido com sucesso!');
  };

  const clickAddress = (id: string) => {
    setIdAddressSelect(id);
    setShowModalAddress(false);
    setShowModalOrder(true);
  };

  const isValidDeliveryOption = option =>
    option !== undefined && option !== null && option.length > 2;

  const fetchDataAndUpdateState = (
    searchParams,
    apiEndpoint,
    setState,
    setOptions,
    isStreet = false,
  ) => {
    api.post(apiEndpoint, searchParams).then(response => {
      const data = response.data.result[0];
      setState(data);
      const options = data.map(element => {
        if (isStreet) {
          return element.DS_LOCALIZACAO !== ''
            ? `${element.NM_LOGRADOURO} | ${element.DS_LOCALIZACAO}`
            : element.NM_LOGRADOURO;
        }
        return element[
          `NM_${apiEndpoint.includes('Cidades') ? 'CIDADE' : 'BAIRRO'}`
        ];
      });
      setOptions(options);
    });
  };

  useEffect(() => {
    if (optionCidadeDelivery !== undefined && optionCidadeDelivery !== null) {
      if (taxaControllRegisterAddress !== DeliveryFeeControll.DISTANCE) {
        if (optionCidadeDelivery.length > 2) {
          const search = {
            _parameters: [
              [
                {
                  ID_DM024: 22,
                  NM_CIDADE: optionCidadeDelivery.trim(),
                },
              ],
            ],
          };
          api
            .post(
              `/%22fprg_GetCidadesJSON%22/${companyData.companyCode}`,
              search,
            )
            .then(response => {
              const dataCity = response.data.result[0];
              const tmp = dataCity.map(element => {
                return element.NM_CIDADE;
              });
              setCity(dataCity);
              setOptionsCity(tmp);
            });
        }
      } else {
        // @ts-ignore
        setOptionsCity([optionCidadeDelivery]);
        // @ts-ignore
        setCity(optionCidadeDelivery);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionCidadeDelivery]);

  useEffect(() => {
    if (optionBairroDelivery !== undefined && optionBairroDelivery !== null) {
      if (taxaControllRegisterAddress !== DeliveryFeeControll.DISTANCE) {
        if (optionBairroDelivery.length > 2 && city[0].ID_DM025 !== undefined) {
          const search = {
            _parameters: [
              [
                {
                  ID_DM025: Number(city[0].ID_DM025),
                  NM_BAIRRO: optionBairroDelivery.trim(),
                },
              ],
            ],
          };
          api.post('/%22fprg_GetBairrosJSON%22/733/', search).then(response => {
            const dataNeighbor = response.data.result[0];
            const tmp = dataNeighbor.map(element => {
              return element.NM_BAIRRO;
            });
            setNeighbor(dataNeighbor);
            setOptionsNeighbor(tmp);
          });
        }
      } else {
        // @ts-ignore
        setNeighbor(optionBairroDelivery.trim());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionBairroDelivery]);

  useEffect(() => {
    if (optionRuaDelivery !== undefined && optionRuaDelivery !== null) {
      if (taxaControllRegisterAddress !== DeliveryFeeControll.DISTANCE) {
        if (
          optionRuaDelivery.length > 2 &&
          neighbor[0].ID_DM081 !== undefined
        ) {
          const search = {
            _parameters: [
              [
                {
                  ID_DM081: Number(neighbor[0].ID_DM081),
                  NM_LOGRADOURO: optionRuaDelivery.split('|')[0].trim(),
                },
              ],
            ],
          };
          api
            .post(`/%22fprg_GetRuasJSON%22/${companyData.companyCode}/`, search)
            .then(response => {
              const dataStreet = response.data.result[0];
              const tmp = dataStreet.map(element => {
                if (element.DS_LOCALIZACAO !== '') {
                  return `${element.NM_LOGRADOURO} | ${element.DS_LOCALIZACAO}`;
                }
                return element.NM_LOGRADOURO;
              });
              dataStreet.forEach(item => {
                if (item.DS_LOCALIZACAO === '') {
                  // eslint-disable-next-line no-param-reassign
                  item.DS_LOCALIZACAO = item.NM_LOGRADOURO;
                }
              });
              setStreet(dataStreet);
              setOptionsStreet(tmp);
              if (optionRuaDelivery.includes('|')) {
                const tmpDataStreetx = dataStreet.filter(val => {
                  return val.DS_LOCALIZACAO.includes(
                    optionRuaDelivery.split('|')[1].trim(),
                  );
                });
                setStreet(tmpDataStreetx);
              } else {
                setStreet(dataStreet);
              }
            });
        }
      } else {
        // @ts-ignore
        setStreet(optionRuaDelivery.split('|')[0].trim());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionRuaDelivery]);

  const saveNewAddress = useCallback(() => {
    const addressInfo = {
      descAddress,
      city,
      neighbor,
      street,
      numeroDelivery,
      complementoDelivery,
    };

    const fields = {
      descAddress: 'descrição para o endereço',
      city: 'cidade',
      neighbor: 'bairro',
      street: 'rua',
    };
    Object.keys(fields).forEach(key => {
      const value = fields[key];
      if (
        !addressInfo[key] ||
        (key === 'descAddress' && addressInfo[key].length <= 1)
      ) {
        toast.error(`O Campo "${value}" é obrigatório!`);
      }
    });

    const data_address = {
      type: 'address',
      ...addressInfo,
    };
    const storageAddress = JSON.parse(
      localStorage.getItem('address_list') || '[]',
    );

    if (storageAddress.length >= 4) {
      toast.error('Número máximo de endereços já adicionados!');
      return;
    }
    const updatedAddressList = [...storageAddress, data_address].filter(
      x => x !== null,
    );
    setStorage('address_list', JSON.stringify(updatedAddressList));
    setaddressList([...returnDefaultAddress(), ...updatedAddressList]);
    toast.success('Endereço adicionado com sucesso!');

    setDescAddress('');
    setCidadeDelivery('');
    setBairroDelivery('');
    setRuaDelivery('');
    setComplementoDelivery('');
    setNumeroDelivery('');
    setShowNewAddress(false);
  }, [
    city,
    complementoDelivery,
    descAddress,
    neighbor,
    numeroDelivery,
    returnDefaultAddress,
    street,
  ]);

  useEffect(() => {
    /*
      why need to parse twice?

      First JSON.parse(): This is necessary because the string you retrieve from
      localStorage is itself a JSON string. You need to convert it from a string
      representation of a JSON string to a JSON string.

      Second JSON.parse(): The second parsing step is required to convert the resulting
      JSON string into a proper JavaScript object.
    */
    const addressListStorage = JSON.parse(
      localStorage.getItem('address_list') || '[]',
    );

    const defaultAddressList = returnDefaultAddress();

    if (addressListStorage !== null && addressListStorage.length > 0) {
      if (
        companyData.setDeliveryAddress === 'S' ||
        companyData.setDeliveryAddress === 'T'
      ) {
        setaddressList([
          ...defaultAddressList,
          ...JSON.parse(addressListStorage),
        ]);
      } else {
        setaddressList(returnDefaultAddress);
      }
    } else {
      setaddressList(returnDefaultAddress);
      setStorage('address_list', []);
    }

    api
      .get(`/fprg_GetFormaPagamentoJSON/`)
      .then(response => {
        let list = response.data.result[0];
        list = list.filter(item => !item.DS_FORMA_PAGAMENTO.endsWith('-WEB'));
        setOptionsPayment(list);
      })
      .catch(e => {
        console.log(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalAddress]);

  useEffect(() => {
    try {
      if (optionsPayment[selectedPayment].ID_TROCO === 1) {
        setControllMChange(true);
      } else {
        setControllMChange(false);
        setMoneyChange('');
      }
    } catch (e) {
      console.log((e as Error).message);
    }
  }, [optionsPayment, selectedPayment]);

  useEffect(() => {
    if (optionsPayment.length > 0 && selectedPayment === 0) {
      if (optionsPayment[0].ID_TROCO === 1) {
        setControllMChange(true);
      } else {
        setControllMChange(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsPayment]);

  useEffect(() => {
    if (getStorage('clientInfo')) {
      const clientInfo = JSON.parse(getStorage('clientInfo'));
      setCustomerId(Number(clientInfo.ID_DM001));
      setClientName(clientInfo.NM_RAZAO_SOCIAL);
      setClientCpf(clientInfo.NR_CNPJ);
      setClientPhone(clientInfo.NR_FONE);
      setClientMail(clientInfo.NR_FONE);
    }
  }, []);

  const filterOptions = (options, state) => {
    return options;
  };

  useEffect(() => {
    let type = 'A';

    if (
      companyData.setDelivery === 'S' &&
      companyData.table === companyData.setDeliveryTable
    ) {
      type = 'D';
    }

    const dataTime = {
      id_dm000: companyData.companyCode,
      type,
    };

    apiAutoatendimento
      .post('validation_time', dataTime)
      .then(response => {
        setTimeStatus(response.data);
      })
      .catch(error => {
        console.log(`Erro ao buscar horarios: ${error}`);
      });
  }, [companyData]);

  return (
    <>
      {isLoading ? (
        <ContainerLoading>
          <Lottie
            options={
              isLoadingType === 'order'
                ? orderAnimation
                : smallShoppingAnimation
            }
            style={{ width: '100%', height: 400 }}
          />
          <h1>{isLoadingText}</h1>
        </ContainerLoading>
      ) : (
        <AnimationContainer>
          <Container>
            <ContentCenter>
              <div id="headerContent">
                {cart.length === 1 ? (
                  <p>{cart.length} item no Carrinho</p>
                ) : (
                  <p>{cart.length} itens no Carrinho</p>
                )}
              </div>
              <div id="listProducts">
                {cart.map(product => (
                  <ProductCart
                    key={product.ID_DM004}
                    props={product}
                    getIdToDelete={handleDeleteProductInCart}
                  />
                ))}
              </div>
              <div id="footerContent">
                {showButtonLastOrder === true &&
                companyData.setDeliveryTable !== companyData.table ? (
                  <div className="divKeepShopping">
                    <button
                      type="button"
                      className="button-outline button-footer"
                      onClick={() => {
                        updateRoute('/');
                        history.push('/');
                      }}
                    >
                      <p>Continuar Comprando</p>
                    </button>
                    <button
                      type="button"
                      id="lastCart"
                      className="button-outline button-footer"
                      onClick={() => {
                        setShowLastOrder(true);
                      }}
                    >
                      <p>Último Pedido</p>
                    </button>
                  </div>
                ) : (
                  <div className="divButton">
                    <button
                      type="button"
                      className="button-keepShoping button-footer"
                      onClick={() => {
                        updateRoute('/');
                        history.push('/');
                      }}
                    >
                      <p>Continuar Comprando</p>
                    </button>
                  </div>
                )}
                {timeStatus.success && (
                  <div className="divButton">
                    <button
                      type="button"
                      className="placeOrder button-footer"
                      onClick={handleFinishedOrder}
                    >
                      <p>Realizar Pedido</p>
                      <p>{formatCurrencyBRL(totalPriceCart)}</p>
                    </button>
                  </div>
                )}
                {companyData.setDelivery === 'S' &&
                companyData.setDeliveryTable === companyData.table ? (
                  <>
                    <Modal
                      visible={showModalOrder}
                      setVisible={setShowModalOrder}
                      maxHeight={30}
                      maxWidth={20}
                      showExit={false}
                    >
                      <p className="text-modal">Verifique seu pedido!</p>
                      {!controllFee && (
                        <p style={{ color: 'red', fontWeight: 'bold' }}>
                          Infelizmente não atendemos nesse endereço!
                        </p>
                      )}
                      <div className="divDetailsOrder">
                        <p>
                          Endereço: {addressListSelect[0].desc_select_address}
                        </p>
                        <br />
                        <p>{`Valor do pedido: ${formatCurrencyBRL(
                          totalPriceCart,
                        )}`}</p>
                        <br />
                        {controllFee && (
                          <>
                            <p>{`Taxa de Entrega: ${addressListSelect[0].vl_select_address}`}</p>
                            <br />
                            <p>{`Total do pedido: ${formatCurrencyBRL(
                              totalPriceCartDelivery,
                            )}`}</p>
                          </>
                        )}
                        <div>
                          <br />
                          <div>
                            <InputLabel
                              htmlFor="demo-controlled-open-select"
                              style={{ textAlign: 'left' }}
                            >
                              Forma de Pagamento
                            </InputLabel>
                            <Select
                              onChange={e => {
                                setSelectedPayment(Number(e.target.value));
                              }}
                              style={{ minWidth: '100%' }}
                              inputProps={{
                                name: 'payment-method',
                                id: 'demo-controlled-open-select',
                                width: '100%',
                              }}
                              value={selectedPayment}
                            >
                              {optionsPayment &&
                                optionsPayment.map((option, index) => (
                                  <MenuItem
                                    value={index}
                                    selected={index === 0}
                                  >
                                    {option.DS_FORMA_PAGAMENTO}
                                  </MenuItem>
                                ))}
                            </Select>

                            {selectedPayment ===
                              optionsPayment.findIndex(
                                option =>
                                  option.IN_TIPO_CHAVEPIX !== undefined &&
                                  option.IN_TIPO_CHAVEPIX,
                              ) && (
                              <>
                                <CopyPixKeyToClipboard
                                  paymentType={optionsPayment.find(
                                    (option, index) =>
                                      option.IN_TIPO_CHAVEPIX !== '',
                                  )}
                                />
                                <SendReceiptToWhatsapp />
                              </>
                            )}
                          </div>
                          <br />
                          {controllMChange && (
                            <TextField
                              label="Trazer troco para:"
                              fullWidth
                              size="medium"
                              value={moneyChange}
                              onChange={event => {
                                setMoneyChange(maskBRL(event.target.value));
                              }}
                            >
                              {' '}
                            </TextField>
                          )}
                        </div>
                      </div>
                      <div className="buttons-modal-cpf">
                        <button
                          type="button"
                          className="button-filled"
                          onClick={() => {
                            handleConfirmOrderDelivery();
                          }}
                          disabled={orderInProgress}
                        >
                          Confirmar
                        </button>
                        <button
                          type="button"
                          className="button-outline"
                          onClick={() => {
                            updateRoute('/');
                            history.push('/');
                            setControllMChange(false);
                            setCurrentAddressDesc('');
                            setCurrentAddressID('');
                          }}
                        >
                          Cancelar
                        </button>
                      </div>
                    </Modal>
                  </>
                ) : (
                  <>
                    <Modal
                      visible={showModalOrder}
                      setVisible={setShowModalOrder}
                      maxHeight={30}
                      maxWidth={20}
                    >
                      <h2 className="text-modal">Deseja enviar o pedido?</h2>
                      <div className="buttons-modal-cpf">
                        <button
                          type="button"
                          className="button-filled"
                          onClick={() => {
                            handleConfirmOrder();
                          }}
                          disabled={orderInProgress}
                        >
                          Sim
                        </button>
                        <button
                          type="button"
                          className="button-outline"
                          onClick={() => {
                            updateRoute('/');
                            history.push('/');
                          }}
                        >
                          Não
                        </button>
                      </div>
                    </Modal>
                  </>
                )}
                <Modal
                  visible={showModalSignUp}
                  setVisible={setShowModalSignUp}
                  maxHeight={30}
                  maxWidth={20}
                  showExit={false}
                >
                  <p className="text-modal"> Informe seus dados</p>
                  <div className="address-modal-delivery">
                    <TextField
                      label="Nome completo"
                      fullWidth
                      size="medium"
                      value={clientName}
                      onChange={event => {
                        setClientName(event.target.value);
                      }}
                    >
                      {' '}
                    </TextField>
                  </div>
                  <div className="address-modal-delivery">
                    <TextField
                      label="CPF ou CNPJ (Opcional)"
                      fullWidth
                      size="medium"
                      value={clientCpf}
                      onChange={event => {
                        if (event.target.value.length > 14) {
                          setClientCpf(maskCNPJ(event.target.value));
                        } else {
                          setClientCpf(maskCPF(event.target.value));
                        }
                      }}
                    >
                      {' '}
                    </TextField>
                  </div>
                  <div className="address-modal-delivery">
                    <TextField
                      label="Telefone de contato"
                      fullWidth
                      size="medium"
                      value={clientPhone}
                      onChange={event => {
                        setClientPhone(maskPhone(event.target.value));
                      }}
                    >
                      {' '}
                    </TextField>
                  </div>
                  <div className="address-modal-delivery">
                    <TextField
                      label="E-mail (Opcional)"
                      fullWidth
                      size="medium"
                      value={clientMail}
                      onChange={event => {
                        setClientMail(event.target.value);
                      }}
                    >
                      {' '}
                    </TextField>
                  </div>
                  <div className="buttons-modal-cpf">
                    <button
                      type="button"
                      className="button-filled"
                      onClick={() => {
                        handleValidation();
                      }}
                    >
                      Confirmar
                    </button>
                    <button
                      type="button"
                      className="button-outline"
                      onClick={() => {
                        setShowModalSignUp(false);
                      }}
                    >
                      Cancelar
                    </button>
                  </div>
                </Modal>
                <Modal
                  visible={showLastOrder}
                  setVisible={setShowLastOrder}
                  maxHeight={30}
                  maxWidth={20}
                >
                  <h2 className="text-modal">
                    {' '}
                    Deseja reabrir o último pedido?
                  </h2>

                  <div className="buttons-modal-cpf">
                    <button
                      type="button"
                      className="button-filled"
                      onClick={loadingLastCart}
                    >
                      Sim
                    </button>
                    <button
                      type="button"
                      className="button-outline"
                      onClick={() => {
                        setShowLastOrder(false);
                      }}
                    >
                      Não
                    </button>
                  </div>
                </Modal>
                <Modal
                  visible={showModalFinishedOrder}
                  setVisible={setShowModalFinishedOrder}
                  maxHeight={60}
                  maxWidth={70}
                >
                  <Lottie
                    options={defaultOptions}
                    style={{ width: '100%', height: 400 }}
                  />
                  <p className="text-modal">Seu pedido foi enviado</p>
                </Modal>
                <Modal
                  visible={showModalAddress}
                  setVisible={setShowModalAddress}
                  maxHeight={200}
                  maxWidth={70}
                >
                  <>
                    <p className="text-modal">
                      <br />
                      <br />
                      Onde você quer receber seu pedido?
                    </p>
                    {addressList ? (
                      addressList.map((address, index) =>
                        getStorage('setLocalConsumption').replaceAll(
                          '"',
                          '',
                        ) === 'N' && address.type !== 'consumir' ? (
                          <>
                            <AddressCard
                              // eslint-disable-next-line react/no-array-index-key
                              key={index}
                              props={{ ...address, index }}
                              deleteAddress={deleteAddress}
                              clickAddress={clickAddress}
                            />
                          </>
                        ) : null,
                      )
                    ) : (
                      <p>
                        <br />
                        Nenhum endereço encontrado.
                      </p>
                    )}
                    {companyData.setDeliveryAddress === 'S' ||
                    companyData.setDeliveryAddress === 'T' ? (
                      <div className="buttons-modal-delivery">
                        <button
                          type="button"
                          className="button-outline"
                          onClick={() => {
                            setShowNewAddress(true);
                          }}
                        >
                          Cadastrar novo endereço
                        </button>
                      </div>
                    ) : (
                      <div className="buttons-modal-delivery">
                        <></>
                      </div>
                    )}
                  </>
                </Modal>
                <Modal
                  visible={showDeleteAddress}
                  setVisible={setShowDeleteAddress}
                  maxHeight={10}
                  maxWidth={20}
                >
                  <h2 className="text-modal"> Deseja Excluir o Endereço?</h2>
                  <p className="text-modal-delete">{currentAddressDesc}</p>
                  <div className="buttons-modal-cpf">
                    <button
                      type="button"
                      className="button-filled"
                      onClick={() => deleteAddressStorage()}
                    >
                      Sim
                    </button>
                    <button
                      type="button"
                      className="button-outline"
                      onClick={() => {
                        setShowDeleteAddress(false);
                      }}
                    >
                      Não
                    </button>
                  </div>
                </Modal>
                <Modal
                  visible={showNewAddress}
                  setVisible={setShowNewAddress}
                  maxHeight={10}
                  maxWidth={20}
                >
                  <p className="text-modal">Cadastrar novo endereço</p>
                  <div className="address-modal-delivery">
                    <TextField
                      label="Descrição do Endereço"
                      fullWidth
                      placeholder="Casa, Trabalho..."
                      size="medium"
                      value={descAddress}
                      onChange={event => {
                        setDescAddress(event.target.value);
                      }}
                    >
                      {' '}
                    </TextField>
                  </div>
                  <div className="address-modal-delivery">
                    <Autocomplete
                      filterOptions={filterOptions}
                      noOptionsText="Não encontrada..."
                      disablePortal
                      freeSolo={
                        taxaControllRegisterAddress ===
                        DeliveryFeeControll.DISTANCE
                      }
                      multiple={false}
                      value={cidadeDelivery}
                      onChange={(event: unknown, newValue: string | null) => {
                        setCidadeDelivery(newValue);
                      }}
                      inputValue={optionCidadeDelivery}
                      onInputChange={(event, newInputValue) => {
                        setOptionCidadeDelivery(newInputValue);
                      }}
                      id="controllable-states-city"
                      options={optionsCity || []}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Digite a cidade..."
                          fullWidth
                          size="medium"
                        />
                      )}
                    />
                  </div>
                  <div className="address-modal-delivery">
                    <Autocomplete
                      filterOptions={filterOptions}
                      noOptionsText="Não encontrado..."
                      disablePortal
                      autoComplete={
                        taxaControllRegisterAddress ===
                        DeliveryFeeControll.DISTANCE
                      }
                      freeSolo={
                        taxaControllRegisterAddress ===
                        DeliveryFeeControll.DISTANCE
                      }
                      multiple={false}
                      value={bairroDelivery}
                      onChange={(event: unknown, newValue: string | null) => {
                        setBairroDelivery(newValue);
                      }}
                      inputValue={optionBairroDelivery}
                      onInputChange={(event, newInputValue) => {
                        setOptionBairroDelivery(newInputValue);
                      }}
                      id="controllable-states-bairro"
                      options={optionsNeighbor}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Digite o bairro..."
                          fullWidth
                          size="medium"
                        />
                      )}
                    />
                  </div>
                  <div className="address-modal-delivery">
                    <Autocomplete
                      filterOptions={filterOptions}
                      noOptionsText="Não encontrada..."
                      freeSolo
                      disablePortal
                      multiple={false}
                      value={ruaDelivery}
                      onChange={(event: unknown, newValue: string | null) => {
                        setRuaDelivery(newValue);
                      }}
                      inputValue={optionRuaDelivery}
                      onInputChange={(event, newInputValue) => {
                        setOptionRuaDelivery(newInputValue);
                      }}
                      id="controllable-states-rua"
                      options={optionsStreet}
                      renderInput={params => (
                        <>
                          <TextField
                            {...params}
                            label="Digite a rua..."
                            fullWidth
                            size="medium"
                          />
                        </>
                      )}
                    />
                  </div>
                  <div className="address-modal-delivery">
                    <TextField
                      label="Número"
                      fullWidth
                      size="medium"
                      onChange={event => {
                        setNumeroDelivery(event.target.value);
                      }}
                    >
                      {' '}
                    </TextField>
                  </div>
                  <div className="address-modal-delivery">
                    <TextField
                      label="Complemento"
                      fullWidth
                      placeholder="Apartamento, Referência..."
                      size="medium"
                      onChange={event => {
                        setComplementoDelivery(event.target.value);
                      }}
                    >
                      {' '}
                    </TextField>
                  </div>
                  <div className="buttons-modal-delivery">
                    <button
                      type="button"
                      className="button-filled"
                      onClick={() => saveNewAddress()}
                    >
                      Salvar
                    </button>
                  </div>
                </Modal>
                <Modal
                  visible={showModalOrderDelivery}
                  setVisible={setShowModalOrderDelivery}
                  maxHeight={5}
                  maxWidth={20}
                  showExit={false}
                >
                  <h2 className="text-modal">Deseja enviar o pedido?</h2>
                  <div className="buttons-modal-cpf">
                    <button
                      type="button"
                      className="button-filled"
                      onClick={() => {
                        sendDeliveryOrder();
                      }}
                      disabled={orderInProgress}
                    >
                      Sim
                    </button>
                    <button
                      type="button"
                      className="button-outline"
                      onClick={() => {
                        updateRoute('/');
                        history.push('/');
                      }}
                    >
                      Não
                    </button>
                  </div>
                </Modal>
                <Modal
                  visible={showModalUpdateProfile}
                  setVisible={setShowModalUpdateProfile}
                  maxHeight={30}
                  maxWidth={20}
                >
                  <h2 className="text-modal">
                    Deseja atualizar os dados cadastrais?
                  </h2>
                  <div className="buttons-modal-cpf">
                    <button
                      type="button"
                      className="button-filled"
                      onClick={() => {
                        handleUpdateCustomer();
                      }}
                    >
                      Sim
                    </button>
                    <button
                      type="button"
                      className="button-outline"
                      onClick={() => {
                        toast.error(
                          'Não possível dar andamento no pedido com dados desatualzados!',
                        );
                        updateRoute('/');
                        history.push('/');
                      }}
                    >
                      Não
                    </button>
                  </div>
                </Modal>
              </div>
            </ContentCenter>
          </Container>
        </AnimationContainer>
      )}
    </>
  );
};

export default Cart;
